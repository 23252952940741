import React from "react";

import {betterMemo} from "js/common/utils/more-memo";
import MultiSelect from "js/admin/kpis/edit-kpis/tabs/common/multi-select";
import Immutable from "immutable";

const ConfigOwnership = betterMemo(
    {displayName: "ConfigOwnership"},
    ({
      kpi,
      availableOwners = Immutable.List(),
      entityNames,
      onChange,
      disabled
    }) => {
      const rootGroupingEntity = kpi.get("readOnlyRootGroupingEntity");

      const currentValue = kpi.getIn(["config", "entityToOwnerTypes"], Immutable.List());
      const currentValueOptions = currentValue
          .entrySeq()
          .flatMap(([entity, ownerTypes]) => {
            const options = []
            ownerTypes.forEach(ownerType => {
              let entityLabel;
              if (entity === "RECORD") {
                entityLabel = entityNames.get(rootGroupingEntity).get("label");
              } else {
                entityLabel = entityNames.get(entity).get("label");
                if (entity === rootGroupingEntity) {
                  entityLabel = "Parent " + entityLabel;
                }
              }
              const ownerLabel = ownerType === "OWNER" ? "Owner" : "Assignee";
              options.push({value: `${entity}:${ownerType}`, label: `${entityLabel} - ${ownerLabel}`});
            });
            return Immutable.fromJS(options);
      });

      const linkedEntitiesOptions = [];
      // Add the readOnlyRootGroupingEntity
      if(rootGroupingEntity) {
        const rootOwnerLabel = `${entityNames.get(rootGroupingEntity).get("label")} - Owner`;
        const rootAssigneeLabel = `${entityNames.get(rootGroupingEntity).get("label")} - Assignee`;
        linkedEntitiesOptions.push({value: `RECORD:OWNER`, label: rootOwnerLabel})
        linkedEntitiesOptions.push({value: `RECORD:ASSIGNEE`, label: rootAssigneeLabel})
      }

      availableOwners
          .forEach(entity => {
              // Added this check to ensure that the entity exists, due clients not having LEAD entity
            if (entityNames.has(entity)) {
                if(rootGroupingEntity === entity) {
                    linkedEntitiesOptions.push({value: `${entity}:OWNER`, label: `Parent ${entityNames.get(entity).get("label")} - Owner`});
                    linkedEntitiesOptions.push({value: `${entity}:ASSIGNEE`, label: `Parent ${entityNames.get(entity).get("label")} - Assignee`})
                } else {
                    const ownerLabel = `${entityNames.get(entity).get("label")} - Owner`;
                    const assigneeLabel = `${entityNames.get(entity).get("label")} - Assignee`;
                    // Add other entities
                    linkedEntitiesOptions.push({value: `${entity}:OWNER`, label: ownerLabel});
                    linkedEntitiesOptions.push({value: `${entity}:ASSIGNEE`, label: assigneeLabel})
                }
            }
          });
      const updateEntityToOwnerType = (ownerTypes) => {
        const ots = ownerTypes.groupBy(ot => ot.split(":")[0]).map(x => x.map(y => y.split(":")[1]));
        onChange(ots)
      }

      let options = Immutable.fromJS(linkedEntitiesOptions).toList();
      const optionSet = options.toSet();
      currentValueOptions.forEach(option => {
        if (!optionSet.has(option)) {
          options = options.push(option);
        }
      })

      return <MultiSelect
          selectedValues={currentValueOptions.map(option => option.get("value"))}
          onChange={updateEntityToOwnerType}
          placeholder="Select Ownership Type"
          options={options}
          label="Owners"
          labelTooltip="Select the ownership fields that this metric should use to determine who is credited with the activity."
          disabled={disabled} />
    });

export default ConfigOwnership;
