import React from "react";
import Immutable from "immutable";

import {indexBy} from "js/common/utils/collections";
import * as Users from "js/common/users";
import * as Groups from "js/common/groups";
import * as TimeframeRepo from "js/common/repo/backbone/timeframe-repo";

const removeComponentLinksFromEntityPath = (entityPath, typeToGroupingEntity) => {
  let newEntityPath = Immutable.List.of(entityPath.first());

  if (entityPath.size > 1) {
    for (let i = 0; i < entityPath.size - 1; i++) {
      const start = entityPath.get(i);
      const end = entityPath.get(i + 1);
      const startEntity = typeToGroupingEntity.get(start);
      if (!startEntity.get("directJoinsTo").includes(end)) {
        break;
      } else {
        newEntityPath = newEntityPath.push(end);
      }
    }
  }

  return newEntityPath;
};

const useConfigFieldChange = (kpi, onChange, key) => {
  return React.useCallback(
      value => {
        if (kpi.getIn(["config", key]) !== value) {
          onChange(kpi.setIn(["config", key], value));
        }
      },
      [key, kpi, onChange]);
};

const useFieldChange = (kpi, onChange, key) => {
  return React.useCallback(
      value => {
        if (kpi.get(key) !== value) {
          onChange(kpi.set(key, value));
        }
      },
      [key, kpi, onChange]);
};

const useQueryParamChange = (kpi, onChange, key) => {
  return React.useCallback(
      value => onChange(setQueryParam(kpi, key, value)),
      [key, kpi, onChange]);
};

const getQueryParam = (kpi, key, defaultValue = null) => kpi.getIn(["queryParams", key], defaultValue);
const setQueryParam = (kpi, key, value) => {
  const oldValue = getQueryParam(kpi, key);
  if (oldValue === value) {
    return kpi;
  } else {
    if (value == null || value === "" || value === "[]" || value === "{}") {
      return deleteQueryParam(kpi, key);
    } else {
      return kpi.setIn(["queryParams", key], value);
    }
  }
};
const deleteQueryParam = (kpi, key) => kpi.deleteIn(["queryParams", key]);

const getQueryType = (kpi, idToTemplate) => {
  const template = idToTemplate.get(kpi.get("templateId")) || Immutable.Map();
  return template.get("queryType");
};

const templateToLabel = template => {
  const subtype = template.get("querySubtype") ? "/" + template.get("querySubtype") : "";
  return template.get("name") + " (" + template.get("queryType") + subtype + ")";
};

const getTableEntityTypeToGroupingEntityType = typeToGroupingEntity => {
  const tableEntities = typeToGroupingEntity
      .valueSeq()
      .flatMap(ge => ge
          .get("tableEntitiesInGroup")
          .map(te => Immutable.Map({tableEntityType: te, groupingEntityType: ge.get("entity")})));
  return indexBy(te => te.get("tableEntityType"), tableEntities).map(tuple => tuple.get("groupingEntityType"));
};

const useMountUnmountEffect = (effectFn, dependencies) => {
  const ref = React.useRef(dependencies);
  React.useEffect(() => {
    ref.current = dependencies;
  }, dependencies);
  React.useEffect(() => {
    return effectFn(ref);
  }, []);
};

const filterPattern = /\[([\w,]+) (\d+) .+?\]/g;

const filterPatternWithoutJoinPath = /\[(\d+) .+?\]/g;

const stripJoinPathsFromFilter = (filter, entityColumnIdToLookupColumn) => {
  return filter.replace(filterPattern, (wholeMatch, joinPathStr, entityColumnIdStr) => {
    const entityColumnId = parseInt(entityColumnIdStr);
    const c = entityColumnIdToLookupColumn.get(entityColumnId);
    return "[" + entityColumnId + " " + c.get("displayLabel") + "]";
  });
};

const addJoinPathsBackIntoFilter = (filter, entityColumnIdToLookupColumn) => {
  return filter.replace(filterPatternWithoutJoinPath, (wholeMatch, entityColumnIdStr) => {
    const entityColumnId = parseInt(entityColumnIdStr);
    const c = entityColumnIdToLookupColumn.get(entityColumnId);
    return "[" + c.get("joinPathStr") + " " + entityColumnId + " " + c.get("displayLabel") + "]";
  });
};

const useCalculatedImmutableState = (generatorFn, dependencies) => {
  const valueRef = React.useRef(null);
  const generatedValue = React.useMemo(generatorFn, dependencies);

  if (!Immutable.is(generatedValue, valueRef.current)) {
    if (Immutable.Map.isMap(generatedValue)) {
      const mergedValue = generatedValue.map((newVal, key) => {
        const oldVal = valueRef.current.get(key);
        if (Immutable.is(newVal, oldVal)) {
          return oldVal;
        } else {
          return newVal;
        }
      });
      valueRef.current = mergedValue;
      return mergedValue;
    } else {
      valueRef.current = generatedValue;
      return generatedValue;
    }
  } else {
    return valueRef.current;
  }
};

const getDefaultTestConfig = () => {
  const currentUserId = Users.getCurrentUser().get("id");
  const userId = Immutable.fromJS(Users.getActiveUsers().toJSON())
      .filter(u => u.get("hasLogin"))
      .map(u => u.get("id"))
      .sort()
      .first();
  const groupId = Groups.getRootGroup().get("id");
  const timeframe = TimeframeRepo.getDefaultForClient().getRawJson();
  const traceLevel = "LIGHT";
  return Immutable.fromJS({
    userId: userId || currentUserId,
    groupId,
    timeframe,
    traceLevel
  });
};

const createNewWrappedKpi = (generatedId, config) => {
  const kpi = {
    id: generatedId,
    name: config.get("name"),
    trueName: config.get("name"),
    visible: true,
    enabled: true,
    deleted: false,
    order: 0,
    templateId: config.get("templateId"),
    valueFormat: "NUMBER",
    targetable: true,
    readOnlyRootGroupingEntity: config.get("genericEntity"),
    overrideParams: {},
    overrideTimeframe: {type: "NONE"},
    queryParams: {entity: config.get("genericEntity")}
  };
  return Immutable.fromJS({
    changed: true,
    isUnsaved: true,
    requiresTest: true,
    columns: [],
    savedName: config.get("name"),
    testConfig: getDefaultTestConfig(),
    kpi,
    combinedKpi: kpi
  });
};

const createNewCombinedKpi = (generatedId, config, parent) => {

  const templateId = parent.getIn(["kpi", "templateId"]);
  const entity = parent.getIn(["kpi", "queryParams", "entity"]);
  const type = config.get("combineType");
  const columnsKpiId = parent.getIn(["kpi", "columnsKpiId"]) || (!parent.get("isUnsaved") && parent.getIn([
    "kpi",
    "id"]));

  let kpi = Immutable.fromJS({
    id: generatedId,
    name: config.get("name"),
    trueName: config.get("name"),
    visible: true,
    enabled: true,
    deleted: false,
    order: 0,
    templateId: templateId,
    columnsKpiId: columnsKpiId,
    valueFormat: parent.getIn(["kpi", "valueFormat"]),
    targetable: parent.getIn(["kpi", "targetable"]),
    readOnlyRootGroupingEntity: parent.getIn(["kpi", "readOnlyRootGroupingEntity"]),
    overrideParams: {},
    overrideTimeframe: {type: "NONE"},
    combineWithKpiId: config.get("combineWithKpiId"),
    combineWithMasterMetricType: config.get("combineWithMasterMetricType"),
    combineType: type ?? "MERGE_WITH_AND",
    isOverridableDateColumn: parent.getIn(["kpi", "isOverridableDateColumn"]),
    ...(parent.getIn(["kpi", "config"]) && {config: {}})
  });

  if (entity) {
    kpi = kpi.setIn(["queryParams", "entity"], entity);
  }

  return Immutable.fromJS({
    changed: true,
    isUnsaved: true,
    requiresTest: true,
    columns: parent.get("columns"),
    savedName: `${parent.get("savedName")} <Child>`,
    testConfig: getDefaultTestConfig(),
    kpi,
    combinedKpi: kpi
  });
};

const isKpiEditable = (kpi, idToTemplate) => {
  const queryType = getQueryType(kpi, idToTemplate);
  const hasNewConfig = !!kpi.get("config");
  return hasNewConfig && !isRestrictedQueryType(queryType);
}

const cleanCombinedKpi = kpi => kpi
    .set("readOnlyCombined", null)
    .set("combineType", null)
    .set("combineWithKpiId", null)
    .set("combineWithMasterMetricType", null)
    .set("combineOptions", null);

const MessageOnlyTab = ({message}) => <div style={{padding: "1rem 1rem 1.5rem 1rem"}}>{message}</div>;

const flattenForwardReport = node => node.get("children", Immutable.List()).flatMap(flattenForwardReport).push(node);

const getQueryTypeReferences = (kpi, masterKpiTypeToKpiId, idToTemplate) => {
  const queryType = getQueryType(kpi, idToTemplate);
  if (queryType === "FORWARD_REPORT") {
    const rootNode = getForwardReportRootNode(kpi);
    const nodeList = rootNode ? flattenForwardReport(rootNode) : Immutable.List();
    return nodeList.map(node => node.get("kpiId") || masterKpiTypeToKpiId.get(node.get("masterKpi")));
  }

  if (queryType === "SIMPLE_SUM") {
    const kpisToSum = kpi.getIn(["queryParams", "kpisToSum"]) ?? Immutable.List();
    return kpisToSum.map(sumKpi => isNaN(sumKpi) ? masterKpiTypeToKpiId.get(sumKpi) : sumKpi);
  }

  return Immutable.List();
};

const getForwardReportRootNode = (kpi) => {
  const hasNewConfig = !!kpi.get("config");
  return hasNewConfig
      ? kpi.getIn(["config", "forwardReport"])
      : kpi.getIn(["queryParams", "forwardReport", "dependentKpiTree"]);
};

const recursiveDependencyLookup = (idToDependencies, ids) => {
  let allDependencies = Immutable.Set();
  let newDependencies = Immutable.Set(ids);
  do {
    newDependencies = newDependencies
        .flatMap(id => idToDependencies.get(id))
        .toSet()
        .subtract(allDependencies);
    allDependencies = allDependencies.union(newDependencies);
  } while (!newDependencies.isEmpty());

  return allDependencies;
};

const visibleComparison = (idA, idB, idToWrappedKpi) => {
  const visibleA = idToWrappedKpi.get(idA).getIn(["kpi", "visible"]);
  const visibleB = idToWrappedKpi.get(idB).getIn(["kpi", "visible"]);
  if (visibleA !== visibleB) {
    return visibleB - visibleA;
  }
  const savedNameA = idToWrappedKpi.get(idA).get("savedName");
  const savedNameB = idToWrappedKpi.get(idB).get("savedName");
  return savedNameA && savedNameB ? savedNameA.localeCompare(savedNameB) : 0;
};

const matchWords = (wrappedKpi, filterText, showOnlyFailingKpis, hasTestError, showOnlyChangedKpis, showOnlyEditableKpis, isCube19User, idToTemplate) => {
  const filterWords = Immutable.List(filterText.toLowerCase().split(" "));
  if (!wrappedKpi || wrappedKpi.getIn(["kpi", "deleted"])) {
    return false;
  }

  const matchTerms = (
      wrappedKpi.getIn(["kpi", "id"]).toString() +
      " " +
      wrappedKpi.getIn(["kpi", "name"]) +
      " " +
      wrappedKpi.getIn(["kpi", "trueName"])
  ).toLowerCase();

  const id = isCube19User ? " " + wrappedKpi.getIn(["kpi", "id"]).toString() : "";
  const fullText = matchTerms + id;
  const matchesFilter = filterWords.every(word => fullText.includes(word.toLowerCase()));

  const matchesFailing = showOnlyFailingKpis ? !!hasTestError(wrappedKpi.get("testResult")) : true;
  const matchesChanged = showOnlyChangedKpis ? !!wrappedKpi.get("changed") || !!wrappedKpi.get(
      "columnsChanged") : true;
  const kpiEditable = (isKpiEditable(wrappedKpi.get("kpi"), idToTemplate) || (isCube19User && !wrappedKpi.getIn(["kpi", "config"])))
  const matchesEditable = showOnlyEditableKpis ? kpiEditable : true;

  return matchesFilter && matchesFailing && matchesChanged && matchesEditable;
};

const getIdToNode = (kpiId, rootNodes, kpiIdToDependentKpis) => {
  let idToNode = Immutable.Map();
  rootNodes.forEach(node => {
    idToNode = idToNode.set(node.get("id"), node);
    let visitedNodeIdToDependencyTypes = Immutable.Map();
    idToNode = getAllDependentNodes(node.get("id"), idToNode, kpiIdToDependentKpis, visitedNodeIdToDependencyTypes);
  });
  return idToNode;
};

const getAllDependentNodes = (nodeId, idToNode, kpiIdToDependentKpis, visitedNodeIdToDependencyTypes) => {

  const dependentKpis = kpiIdToDependentKpis.get(nodeId, Immutable.List());
  dependentKpis.forEach(dk => {
    if (visitedNodeIdToDependencyTypes.get(dk.get("id")) === dk.get("dependencyType")) {
      return;
    } else {
      idToNode = idToNode.updateIn(
          [nodeId, "edgesOut"],
          Immutable.Map(),
          map => {
            if (map.get(dk.get("id"))) {
              return map.updateIn(
                  [dk.get("id"), "dependencyTypes"],
                  Immutable.Set(),
                  types => types.add(dk.get("dependencyType")));
            } else {
              return map.set(
                  dk.get("id"),
                  Immutable.fromJS({nodeId: dk.get("id"), dependencyTypes: Immutable.Set([dk.get("dependencyType")])}));
            }
          });
      if (idToNode.get(dk.get("id"))) {
        idToNode = idToNode
            .updateIn(
                [dk.get("id"), "dependencyTypeToParents", dk.get("dependencyType")],
                Immutable.Set(),
                parents => parents.add(nodeId));
      } else {
        idToNode = idToNode.set(dk.get("id"), dk
            .set("dependencyTypeToParents", Immutable.Map({[dk.get("dependencyType")]: Immutable.Set([nodeId])}))
            .delete("dependencyType")
        );
        idToNode = getAllDependentNodes(
            dk.get("id"),
            idToNode,
            kpiIdToDependentKpis,
            visitedNodeIdToDependencyTypes.set(dk.get("id"), dk.get("dependencyType")));
      }
      if (dk.get("dependencyType") === "COMBINATION") {
        idToNode = idToNode.setIn([dk.get("id"), "combineType"], dk.get("combineType"));
      }
    }
  });
  return idToNode;
};

const restrictedQueryTypes = [
  "SIMPLE_SUM",
  "FORWARD_REPORT",
  "CONTRACT_INVOICE_MBM",
  "CONTRACT_INVOICE_WBW",
  "PERM_INVOICE_MBM"];
const isRestrictedQueryType = queryType => restrictedQueryTypes.includes(queryType);

const joinPathToString = joinPath => joinPath?.map(segment => {
  const tableEntity = segment.get("tableEntity");
  const contextFromParent = segment.get("contextFromParent");
  const skipJoin = segment.get("skipJoin");
  if (contextFromParent === "NONE" && !skipJoin) {
    return tableEntity;
  } else {
    return `${tableEntity}+${contextFromParent}${skipJoin ? "+SKIP" : ""}`;
  }
}).join();

const stringToJoinPath = strPath => {
  const path = strPath.split(",").map(segment => {
    const splitSegment = segment.split("+");
    const tableEntity = splitSegment[0];
    const contextFromParent = splitSegment[1] ? splitSegment[1] : "NONE";
    const skipJoin = splitSegment[2] === "SKIP";
    return {tableEntity, contextFromParent, skipJoin};
  });
  return Immutable.fromJS(path);
}

export {
  useMountUnmountEffect,
  useCalculatedImmutableState,

  useConfigFieldChange,
  useFieldChange,
  useQueryParamChange,
  getQueryParam,
  setQueryParam,
  deleteQueryParam,
  getQueryType,
  templateToLabel,

  getTableEntityTypeToGroupingEntityType,
  removeComponentLinksFromEntityPath,
  stripJoinPathsFromFilter,
  addJoinPathsBackIntoFilter,
  filterPattern,
  filterPatternWithoutJoinPath,
  MessageOnlyTab,
  createNewCombinedKpi,
  createNewWrappedKpi,
  getDefaultTestConfig,
  cleanCombinedKpi,
  getQueryTypeReferences,
  recursiveDependencyLookup,
  matchWords,
  visibleComparison,
  getIdToNode,
  isKpiEditable,
  joinPathToString,
  stringToJoinPath,

  isRestrictedQueryType
};
