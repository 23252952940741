import React from "react";
import Immutable from "immutable";

import * as Rata from "js/common/utils/remote-data";
import * as GridUtils from "js/dashboards/components/grids/utils";
import * as Ajax from "js/common/ajax";
import * as DashboardUtils from "js/dashboards/utils";
import * as Formatter from "js/common/utils/formatter";
import * as Users from "js/common/users";
import {indexByJs} from "js/common/utils/collections";
import useDimensions from "js/common/utils/use-dimensions";
import useMountEffect from "js/common/utils/use-mount-effect";

import {AgGridReact} from "ag-grid-react";
import "ag-grid-enterprise";
import {CustomThemeContext} from "js/common/themes/CustomThemeProvider";
import CellDialogContent from "js/dashboards/cell-dialog-content";
import GridEditor from "js/dashboards/components/grids/grid-editor";
import TextField from "js/common/views/inputs/text-field";
import * as NovoComms from "js/common/utils/novo-comms";
import {getIsDeepLinkable} from "js/common/utils/novo-comms";
import ChartTypeSelector from "js/dashboards/components/grids/chart-type-selector";
import ErrorMsg from "js/common/views/error";

const getMainMenuItems = () => ["pinSubMenu", "autoSizeThis", "autoSizeAll", "expandAll", "contractAll"];

const valueGetterForKey = key => params => {
  if (!params.node.group) {
    const cell = params.data[key];
    if (cell) {
      return cell.value;
    } else {
      return null;
    }
  }
};

const hasValue = x => x !== undefined && x !== null;

const valueFormatterForKey = (
    key,
    labelToColumn,
    jsColumnStates,
    pivotRows,
    getQuickFilterText,
    filterModelRef
) => {
  return params => {
    const column = labelToColumn[key] || {};

    if (params.node.group) {
      const rowGroupKeys = GridUtils.getRowGroupKeys(params.node);
      const pivotKeys = params.colDef.pivotKeys || [];
      const rowGroupColIds = params.columnApi.getRowGroupColumns().map(c => c.getColId());
      const pivotColIds = params.columnApi.getPivotColumns().map(c => c.getColId());
      const matchingRows = GridUtils.getMatchingRows(
          rowGroupColIds,
          rowGroupKeys,
          pivotColIds,
          pivotKeys,
          pivotRows,
          getQuickFilterText(),
          filterModelRef.current.toJS());

      const currencyCodes = new Set(matchingRows
          .map(row => {
            const cell = row[key] || {};
            return cell.currency;
          })
          .filter(x => x));

      if (currencyCodes.size === 1 && hasValue(params.value)) {
        const maxDecimalPlaces = Immutable.List(matchingRows
            .map(row => {
              const cell = row[key] || {};
              return cell.decimalPlaces || 0;
            }))
            .max();
        const onlyCurrencyCode = currencyCodes.values().next().value;
        const options = {currencyCode: onlyCurrencyCode, decimalPlaces: maxDecimalPlaces};
        return Formatter.format({value: params.value}, options);
      } else if (GridUtils.isNumber(params.value)) {
        const formatOptions = {valueFormat: GridUtils.dataTypeToValueFormat(column.dataType)};
        return Formatter.format({value: params.value}, formatOptions);
      } else if (params.value && params.value.count && params.value.value) {
        const formatOptions = {valueFormat: GridUtils.dataTypeToValueFormat(column.dataType)};
        return Formatter.format({value: params.value.value}, formatOptions);
      } else if (column.dataType === "DATE") {
        return new Date(params.value);
      } else {
        return params.value;
      }
    } else {
      const cell = params.data[key];
      if (cell) {
        if (GridUtils.isNumber(cell.value)) {
          const formatOptions = {valueFormat: GridUtils.dataTypeToValueFormat(column.dataType)};
          return Formatter.format(cell, formatOptions);
        } else {
          return cell.value;
        }
      } else {
        return null;
      }
    }
  };
};

const toPivotRows = (columns, rows) => {
  if (rows.length === 0) {
    return [];
  }

  const firstRow = rows[0];
  const alreadyParsedToPivotRows = !Array.isArray(firstRow);
  if (alreadyParsedToPivotRows) {
    return rows;
  }

  const labels = columns.map(column => column.label);
  const objRows = [];
  for (let rowIndex = 0; rowIndex < rows.length; rowIndex++) {
    const row = rows[rowIndex];
    let objRow = {};
    for (let columnIndex = 0; columnIndex < row.length; columnIndex++) {
      objRow[labels[columnIndex]] = row[columnIndex];
    }
    objRows.push(objRow);
  }
  return objRows;
};

const orderedLabels = [
  "Entity Name",
  "Kpi Name",
  "Kpi Date",
  "Kpi Value",
  "Owner",
  "Group Hierarchy"];

const UiGrid = React.memo(({
  componentId,
  title,
  data,
  config,
  onConfigChange,
  isEditing,
  enableCharts,
  onRequestDialog,
  queueDataLoad,
  componentTypeToTemplate,
  parentConfig,
  downloadFnRef
}) => {
  const {rows, columns, rowLimitReached} = Rata.getValue(data);
  const columnLabels = React.useMemo(() => new Set(columns.map(c => c.label)), [columns]);
  const pivotRows = React.useMemo(() => toPivotRows(columns, rows), [rows, columns]);
  const gridRef = React.useRef();

  useMountEffect(() => {
    if (config.get("showAllColumns")) {
      const allColumnStates = Immutable.fromJS(columns.filter(c => c.derivedFor.length === 0 || c.derivedFor[0].indexOf(
          "DATE") !== 0)
          .map(c => {
            return {
              hide: false,
              colId: c.label,
              order: c.order
            };
          })).sortBy(c => {
        let index = c.get("order") || orderedLabels.indexOf(c.get("colId"));
        if (index === -1) {
          index = 99;
        }
        return index + "_" + c.get("colId");
      });
      onConfigChange(componentId, config.set("columnStates", allColumnStates));
    }
  });

  const columnStates = config.get("columnStates", Immutable.List());
  const labelToColumn = React.useMemo(() => indexByJs(x => x.label, columns), [columns]);
  const [quickFilterText, setQuickFilterText] = React.useState("");
  const [disableChart, setDisableChart] = React.useState(false);
  const filterModel = config.get("filterModel", Immutable.Map());
  const filterModelRef = React.useRef(filterModel);

  const columnDefs = React.useMemo(
      () => {
        const jsColumnStates = columnStates.toJS();
        return GridUtils.columnsToDefs(
            columns,
            jsColumnStates,
            valueGetterForKey,
            key => valueFormatterForKey(
                key,
                labelToColumn,
                jsColumnStates,
                pivotRows,
                () => quickFilterText,
                filterModelRef));
      },
      [pivotRows, columns, columnStates, labelToColumn, quickFilterText]);

  const {theme} = React.useContext(CustomThemeContext);
  const chartElRef = React.useRef(null);
  const [dimRef, {height}] = useDimensions();
  const [chartId, setChartId] = React.useState();
  const {gridTheme, chartTheme} = GridUtils.getThemes(theme.themeId);

  const handleCellClick = React.useCallback(e => {
    const rowGroupKeys = GridUtils.getRowGroupKeys(e.node);
    const pivotKeys = e.colDef.pivotKeys || [];
    const rowGroupColIds = e.columnApi.getRowGroupColumns().map(c => c.getColId());
    const pivotColIds = e.columnApi.getPivotColumns().map(c => c.getColId());
    if (rowGroupKeys.length > 0 || (pivotKeys.length > 0 && config.getIn(["gridProps", "pivotMode"], true))) {
      const matchingRows = GridUtils.getMatchingRows(
          rowGroupColIds,
          rowGroupKeys,
          pivotColIds,
          pivotKeys,
          pivotRows,
          quickFilterText,
          filterModel.toJS());
      const filterTitle = GridUtils.getFilterTitle(rowGroupColIds, rowGroupKeys, pivotColIds, pivotKeys);
      if (matchingRows.length > 0) {
        onRequestDialog(
            <CellDialogContent
                fileName={title ? title + "  " + filterTitle : filterTitle}
                rows={matchingRows}
                columns={columns}
                rowLimitReached={rowLimitReached}
                parentConfig={parentConfig}
                parentGridConfig={config}
                onRequestDialog={onRequestDialog}
                queueDataLoad={queueDataLoad}
                componentTypeToTemplate={componentTypeToTemplate} />,
            {id: "ui-grid-cell-dialog-" + Math.random(), title: filterTitle});
      }
    } else if (getIsDeepLinkable(e.data[e.colDef.colId].deepLinkingData)) {
      const deepLinkingData = e.data[e.colDef.colId].deepLinkingData;
      NovoComms.open(deepLinkingData.crmEntityName, deepLinkingData.crmId);
    } else {
      // TODO PAYBILL could potentially show a click-through for just the row that was clicked on
      //  useful for seeing extra columns without reloading whole grid
      //  also useful if click-through contains more views like txn master report
    }
  }, [
    title,
    pivotRows,
    onRequestDialog,
    columns,
    rowLimitReached,
    parentConfig,
    config,
    queueDataLoad,
    componentTypeToTemplate,
    quickFilterText,
    filterModel
  ]);

  React.useEffect(() => {
    if (config.get("colOrder")) {
      if (gridRef && gridRef.current && gridRef.current.api) {
        gridRef.current.columnApi.applyColumnState({
          state: config.get("colOrder").toJS(),
          defaultState: {sort: null}
        });
      }
    }
    // Mount a chart if we don't have one
    const activeCharts = gridRef?.current.api?.chartService?.activeCharts;
    const hasActiveCharts = activeCharts && activeCharts.size > 0;
    if (enableCharts && config.getIn(["gridProps", "pivotMode"]) !== false && !chartId && activeCharts && !hasActiveCharts) {
      GridUtils.mountChart(config, chartTheme, chartElRef.current, gridRef.current.api, false);
    }

    const groupedRowsCount = columnDefs && columnDefs.filter(c => c.rowGroup === true).length;
    const columnLabelCount = columnDefs && columnDefs.filter(c => c.pivot === true).length;
    setDisableChart(groupedRowsCount > 1 || columnLabelCount > 1);
  }, [config, columnDefs, gridRef, chartElRef, enableCharts, chartId, chartTheme]);

  const handleFirstDataRendered = React.useCallback(e => {
    e.columnApi.autoSizeAllColumns();
  }, []);

  const handleGridReady = React.useCallback(e => {
    e.api.setFilterModel(filterModel.toJS());
    if (config.get("colOrder")) {
      e.columnApi.applyColumnState({
        state: config.get("colOrder").toJS(),
        defaultState: {sort: null}
      });
      if (!chartId && enableCharts && config.getIn(["gridProps", "pivotMode"]) !== false) {
        GridUtils.mountChart(config, chartTheme, chartElRef.current, e.api, false);
      }
    }
  }, [filterModel, config, chartId, enableCharts, chartTheme]);

  const onChartCreated = React.useCallback((event) => {
    // Set chart ID, this can be used with gridRef to get stuff for our actual chart
    setChartId(event.chartId);
  }, [componentId, setChartId]);

  const onChartOptionsChanged = React.useCallback((event) => {
    // After the ag-grid chart type is changed also set it in config so that it persists
    const chartType = event.chartType;
    onConfigChange(componentId, config => config.setIn(["chart", "type"], chartType));
  }, [componentId, onConfigChange]);

  // TODO: Work out why a∂ding config to deps here is breaking everything
  React.useEffect(() => {
    const api = gridRef && gridRef.current && gridRef.current.api;
    if (api && enableCharts && gridRef.current?.props.pivotMode !== false) {
      GridUtils.reMountChartOnThemeChange(config, gridRef, chartTheme, chartElRef, false);
    }
  }, [theme, chartElRef, gridRef, chartTheme, enableCharts]);

  React.useEffect(() => {
    const api = gridRef && gridRef.current && gridRef.current.api;
    if (api && enableCharts && config.getIn(["gridProps", "pivotMode"]) !== false) {
      GridUtils.openChartToolPanelsOnEdit(isEditing, gridRef);
    }
  }, [config, isEditing, gridRef, enableCharts]);


  const gridStateSyncFns = React.useMemo(
      () => GridUtils.keepGridStateInSync(componentId, columnLabels, onConfigChange, filterModelRef),
      [componentId, columnLabels, onConfigChange]);

  const getDownloadObject = () => {
    const hasPermission = Users.currentHasPermission("EXPORT_FILE");
    let tooltipText;
    if (hasPermission) {
      tooltipText = "Download";
    } else {
      tooltipText = "Ask an admin user for the 'Export To File' permission to download this data";
    }
    return (
        {
          enabled: hasPermission,
          tooltip: tooltipText,
          downloadFn: () => {
            if (gridRef.current) {
              return gridRef.current.api.exportDataAsExcel({
                fileName: GridUtils.getExportFileName(config, title),
                processCellCallback(params) {
                  const key = params.column.getColDef().headerName;
                  let valueFormatter = valueFormatterCache[key];
                  if (!valueFormatter) {
                    valueFormatter = valueFormatterForKey(
                        key,
                        labelToColumn,
                        columnStates.toJS(),
                        pivotRows,
                        () => quickFilterText,
                        filterModelRef);
                    valueFormatterCache[key] = valueFormatter;
                  }
                  // NOTE params differs from what we expect based on other grid param uses
                  // these assignments fill in the gaps
                  params.colDef = params.column.getColDef();
                  params.data = params.node.data || {};
                  return valueFormatter(params);
                }
              });
            }
          }
        });
  };

  downloadFnRef.current = () => getDownloadObject();

  const {gridWidth, chartWidth} = GridUtils.getGridAndChartWidth(config);
  const chartEl = enableCharts && <div
      ref={chartElRef}
      className={theme.themeId === "light"
          ? "ag-theme-alpine"
          : "ag-theme-alpine-dark"}
      style={{width: chartWidth, height: title ? height - 30 : height}} />;
  const valueFormatterCache = {};

  const overlayStyle = {
    padding: "0 3rem",
    display: "flex",
    alignItems: "center",
    top: 0,
    right: 0,
    position: "absolute",
    background: theme.themeId === "light" ? "rgba(255, 255, 255, 1)" : "rgba(39, 39, 49, 1)",
    width: chartWidth,
    height: title ? (height + 100) - 30 : height + 100,
    textAlign: "center",
    justifyContent: "center"
  };

  return <div style={{width: "100%", height: "100%", display: "flex", flexFlow: "column"}}>
    {rowLimitReached && <ErrorMsg
        type="warn"
        text={"A limit of 500k rows are shown below. If you need to see data that is not loaded, please adjust your filters."} />}
    <div style={{margin: "10px", display: "flex"}}>
      {isEditing && !config.get("disableCharting") && <ChartTypeSelector
          componentId={componentId}
          config={config}
          onConfigChange={onConfigChange} />}
      {(config.get("downloadEnabled") || config.get("isClickThrough")) &&
          <GridUtils.DownloadButton onClick={() => getDownloadObject().downloadFn()} />}
      <TextField
          placeholder="Search"
          style={{
            width: 300,
            alignSelf: "flex-end",
            marginRight: 0,
            marginLeft: "auto"
          }}
          value={quickFilterText}
          onChange={e => setQuickFilterText(e.target.value)} />
    </div>

    <div style={{display: "flex", height: "100%", overflow: "hidden"}}>
      <div ref={dimRef} style={{flex: 1, width: gridWidth, height: "100%"}}>
        <div className={gridTheme} style={{display: "flex", flexDirection: "column", height}}>
          <AgGridReact
              {...GridUtils.defaultGridProps}
              {...config.get("gridProps", Immutable.Map()).toJS()}
              {...gridStateSyncFns}
              quickFilterText={quickFilterText}
              ref={gridRef}
              enableCharts={enableCharts && config.getIn(["gridProps", "pivotMode"]) !== false}
              onChartCreated={onChartCreated}
              onChartOptionsChanged={onChartOptionsChanged}
              customChartThemes={GridUtils.customChartThemes}
              chartThemes={GridUtils.chartThemes}
              onGridReady={handleGridReady}
              getMainMenuItems={getMainMenuItems}
              sideBar={isEditing}
              onCellClicked={handleCellClick}
              onFirstDataRendered={handleFirstDataRendered}
              rowData={pivotRows}
              columnDefs={columnDefs}
              defaultGroupOrderComparator={GridUtils.groupOrderComparator} />
        </div>
      </div>
      {chartEl}
      {config.getIn(["gridProps", "pivotMode"]) === false && (config.getIn(["chart", "position"])
              === "FULL"
              || config.getIn(["chart", "position"])
              === "LEFT") &&
          <div style={overlayStyle}>
            Pivot charts require pivot mode to be enabled.
          </div>
      }
      {disableChart && (config.getIn(["chart", "position"])
              === "FULL"
              || config.getIn(["chart", "position"])
              === "LEFT") &&
          <div style={overlayStyle}>
            Charting is not available for multiple row groups or column labels
          </div>
      }
    </div>
  </div>;
});


export default Immutable.fromJS({
  type: "UiGrid",
  label: "Small Data Grid",
  getDefaultData: () => ({rows: [], columns: [], rowLimitReached: false}),
  getReactComponent: () => UiGrid,
  getEditorReactComponent: () => GridEditor,
  getTitle: component => component.get("title"),
  canFullScreen: true,
  canDownload: true,
  variant: "scrollable",
  layout: {
    min: {width: 12, height: 12},
    max: {width: 48, height: 24},
    init: {width: 24, height: 16}
  },
  applyParentConfig: (parentConfig, dataConfig) => DashboardUtils.applyInheritanceForKeys(parentConfig, dataConfig),
  load: (component) => {
    const config = component.get("dataConfig");
    return Ajax
        .post({
          url: "reporting/ui-grid",
          json: {
            params: GridUtils.configToReqParams(config),
            kpiIds: config.get("kpiIds", Immutable.List())
          }
        })
        .then(response => {
          return {rows: response.rows, columns: response.columns, rowLimitReached: response.rowLimitReached};
        });
  }
});