import React from "react";
import moment from "moment";
import store from "store";
import Immutable from "immutable";

import {reactComponentToMarionetteComponent} from "js/common/views/react-to-marionette";

import CubeTvChannelsSelectionApp from "js/cubetv/channel-selection/app";
import NewsTicker from "js/cubetv/cubes/news-ticker";

import currentClient from "js/common/repo/backbone/current-client";
import * as Ajax from "js/common/ajax";
import * as ajax from "js/common/ajax";
import * as Auditor from "js/common/auditer";
import * as Groups from "js/common/groups";
import * as Users from "js/common/users";
import {currentQueryString} from "js/common/utils/query-strings";
import {calculateClientFlashTime} from "js/common/utils/ntp";

import Cube19 from "js/cube19.app";
import LatestVersionDialog, {currentUiVersionInfo, loadLatestUiVersion} from "js/common/views/latest-version-dialog";


const JST = window.JST;
const Backbone = window.Backbone;
const $ = window.$;
const Marionette = window.Marionette;
const _ = window._;
export const CUBETV_MIN_LENGTH_MILLIS = 60000;
const millisFor2Weeks = 1209600000;
const millisFor4Hours = 14400000;

const PageLayout = Backbone.Model.extend({

  //TECH DEBT: could be taken from config if cube configs stored in an array?
  regionNames: {
    EmbeddedVideo: ["fullscreen"],
    Iframe: ["fullscreen"],
    Fullscreen: ["fullscreen"]
  },

  initialize() {
    this.regions = {};
    this.cubeModels = [];
    const layoutId = this.get("id");
    if (layoutId === "LeftBigRightSmall") {
      const leaderboardSlideConfig = {
        leaderboardConfig: this.get("left")[0],
        topPerformersConfig: this.get("right")[0],
        groupOverviewConfig: this.get("right")[1]
      };
      this.leaderboardModel = new Cube19.Models.Cubes.Leaderboard(leaderboardSlideConfig, {parse: true});
    } else if (layoutId === "LeftBigRight4") {
      const trendSlideConfig = {
        trendConfig: this.get("left")[0],
        kpiSummariesConfig: this.get("right")[0]
      };
      this.trendModel = new Cube19.Models.Cubes.Trend(trendSlideConfig, {parse: true});
    } else {
      const regionNames = this.regionNames[layoutId];
      regionNames.forEach(regionName => {
        this.regions[regionName] = [];
        const cubeConfigs = this.get(regionName);
        cubeConfigs.forEach(cubeConfig => {
          const CubeModelClass = Cube19.Models.Cubes[cubeConfig.visualizationId];
          const cubeModel = new CubeModelClass(cubeConfig, {parse: true});

          this.regions[regionName].push(cubeModel);
          this.cubeModels.push(cubeModel);
        });
      });
    }
  },

  load() {
    const layoutId = this.get("id");
    if (layoutId === "LeftBigRightSmall") {
      this.leaderboardModel.load();
    } else if (layoutId === "LeftBigRight4") {
      this.trendModel.load();
    } else {
      this.cubeModels.forEach(cubeModel => cubeModel.load());
    }
  },

  getCubeModelsForRegion(regionName) {
    return this.regions[regionName];
  },

  getCubeModelForRegion(regionName, index) {
    index = index || 0;
    return this.regions[regionName][index];
  }

});

const Page = Backbone.Model.extend({

  initialize() {
    const layoutConfig = this.get("layout");
    const layout = new PageLayout(layoutConfig);
    this.set("layout", layout);
  },

  load() {
    this.get("layout").load();
  }

});

const Pages = Backbone.Collection.extend({
  model: Page
});

export default Marionette.LayoutView.extend({
  template: JST["cubetv/layout"],
  className: "cubetv-app",

  regions: {
    headerRegion: ".header-region",
    menuRegion: ".menu-region",
    centreRegion: ".center-region",
    footerRegion: ".footer-region",
    dialogRegion: ".dialog-region"
  },

  ui: {
    headerRegion: ".header-region"
  },

  initialize(options) {
    if (options.channelId) {
      this.configIdToLocalStorage(options.channelId);
    }

    Cube19.commands.setHandler("cubetv:show-next", this.tryNextPageMove, this);
    Cube19.reqres.setHandler("cubetv:next-deal", this.getNextDeal, this);

    this.flashedDealIds = new Set();
    this.dealFlashes = [];

    this.latestFlashedDealId = 0;
    this.dealFlashIntervalId = null;

    this.pollDealFlashes();
  },

  pollDealFlashes() {
    this.dealFlashIntervalId = setInterval(() => {
      Ajax.get({url: "/dealflash/deal-flashes?uiUtcNowInMs=" + moment.utc().valueOf()})
          .then(response => {
            const clientTimestampOfResponseReceptionInMs = moment.utc().valueOf();
            response.dealFlashes
                .sort((df_a, df_b) => df_a.id - df_b.id)
                .forEach(df => {
                  if (this.latestFlashedDealId < df.id) {
                    this.latestFlashedDealId = df.id;
                    const clientTimestampOfFlashInIm = calculateClientFlashTime(
                        df.flashTimeInMs,
                        response.clientTimestampOfRequestTransmissionInMs,
                        response.serverTimestampOfRequestReceptionInMs,
                        response.serverTimestampOfResponseTransmissionInMs,
                        clientTimestampOfResponseReceptionInMs);
                    const delay = clientTimestampOfFlashInIm - moment.utc().valueOf();

                    if (delay > 0) {
                      setTimeout(() => {
                        this.onDealFlash({
                          message: JSON.stringify(df.payload)
                        });
                      }, delay);
                    }
                  }
                });
          }, () => {
            Auditor.audit("deal-flash:polling-error");
          });
    }, 10000);
  },

  configIdToLocalStorage(configId) {
    localStorage.setItem(this.getCurrentUsersBSConfigKey(), configId);
  },

  getConfigIdFromLocalStorage() {
    return localStorage.getItem(this.getCurrentUsersBSConfigKey());
  },

  getCurrentUsersBSConfigKey() {
    return `bsConfig-user-${Users.getCurrentUser().get("id")}`;
  },

  onShow() {
    const body = $("body");
    const client = currentClient;
    const theme = localStorage.getItem("appTheme");
    const className = client.hasPermission("HAS_NEW_LOOK_CUBETV") ? "cubetv new-design" :
        "cubetv old-design";
    body.removeClass().addClass(className).addClass(`theme-${theme}`);
    ;

    this.ui.headerRegion.hoverIntent({
      over: _.bind(this.showMenu, this),
      out: _.bind(this.hideMenu, this),
      timeout: 1
    });

    this.handleKeyDown = this.handleKeyDown.bind(this);
    body.on("keydown", this.handleKeyDown);

    this.loadConfig();

    window.cubeTvRunning = true;
  },

  onDestroy() {
    const body = $("body");
    body.off("keydown", this.handleKeyDown);

    window.clearTimeout(this.nextPageTimeoutId);
    window.clearTimeout(this.loopTimeoutId);

    // Polling
    if (this.dealFlashIntervalId) {
      clearInterval(this.dealFlashIntervalId);
      this.dealFlashIntervalId = null;
    }

    body.removeClass();

    window.cubeTvRunning = false;
  },

  handleKeyDown(event) {
    const keyToHandler = {
      "ArrowLeft": this.handlePrevPageMove.bind(this),
      "ArrowUp": this.handleFirstPageMove.bind(this),
      "ArrowRight": this.tryNextPageMove.bind(this)
    };

    const identity = x => x;
    const handler = keyToHandler[event.key] || identity;
    handler();
  },

  showMenu() {
    const buttonStyle = {
      background: "rgba(0, 0, 0, 0.52)",
      boxShadow: "0 0 5px 2px rgba(0,0,0,.35)",
      border: "1px solid #fff",
      borderRadius: 3,
      color: "#fff",
      margin: "0 1rem",
      cursor: "pointer"
    };
    const currentUser = Users.getCurrentUser();
    const isCube19User = Users.isCube19User(currentUser);
    const menu = reactComponentToMarionetteComponent(
        <div style={{float: "right"}}>
          {isCube19User &&
              <button style={buttonStyle} onClick={this.handleLocalDealFlashClick.bind(this)}>
                Local Deal Flash
              </button>}
          {isCube19User &&
              <button style={buttonStyle} onClick={this.handleGlobalDealFlashClick}>
                Global Deal Flash
              </button>}
          <button style={buttonStyle} onClick={this.goToCubeTvChannelsSelectionApp}>
            Change Channel
          </button>
        </div>
    );
    this.menuRegion.show(menu);
  },

  handleLocalDealFlashClick() {
    Ajax.get({url: "/dealflash/fake-deal-details"})
        .then(dealDetails => {
          const fakeMessage = {
            message: JSON.stringify(dealDetails)
          };
          this.onDealFlash(fakeMessage);
          console.log("Local fake deal flash triggered");
        }, () => {
          Auditor.audit("deal-flash:local-fake-deal-error");
        });
  },

  handleGlobalDealFlashClick() {
    Ajax.get({url: "/dealflash/fake-deal"})
        .then(() => {
          console.log("Global fake deal flash triggered");
        }, () => {
          Auditor.audit("deal-flash:fake-deal-error");
        });
  },

  hideMenu() {
    this.menuRegion.empty();
  },

  goToCubeTvChannelsSelectionApp() {
    Cube19.contentRegion.show(reactComponentToMarionetteComponent(
        <CubeTvChannelsSelectionApp stopKioskMode={true} autoPlaySingleChannel={false} />
    ));
  },

  loadConfig() {
    const configId = this.getConfigIdFromLocalStorage();
    if (configId) {
      Ajax.get({
        url: "carousels/" + configId,
        fatalError: true
      }).then(response => this.onConfigLoad(response));
    } else {
      Ajax.get({
        url: "carousels/legacy",
        fatalError: true
      }).then(response => this.onConfigLoad(response));
    }
  },

  onConfigLoad(response) {
    const cubeTvConfig = response.json;
    this.channelId = response.id;
    this.channelName = response.name;
    this.pages = new Pages(cubeTvConfig.pages);
    this.newsfeeds = new Backbone.Model(cubeTvConfig.newsfeeds);
    this.dealflashPageModel = new Page(cubeTvConfig.dealflash);

    try {
      this.handleFirstPageMove();
    } catch (e) {
      console.error("error during show of first page / loading second page", e);
    }
  },

  onDealFlash(payload) {
    const message = JSON.parse(payload.message);
    const placementId = message.placementId;
    const users = message.users.map(({userId, groupId, flashId}) => ({
      userId,
      flashId,
      allowedToFlashForUser: this.allowedToViewDealForUser(groupId)
    }));
    const parentId = message.parentId;
    const alreadyFlashed = this.flashedDealIds.has(parentId);
    const channelId = this.channelId;
    Auditor.uniqueAudit("deal_flash:received", {
      parentId,
      placementId,
      users,
      alreadyFlashed,
      channelId
    }, {
      userAgent: true
    });
    const usersToFlash = users.filter(user => user.allowedToFlashForUser);

    if (alreadyFlashed || usersToFlash.length === 0) {
      return;
    }

    Promise.all(usersToFlash.map(userToFlash => Ajax
        .get({url: "dealflash/details", data: {placementId, userId: userToFlash.userId}})
        .then(dealFlashDto => {
          dealFlashDto.parentId = parentId;
          dealFlashDto.flashId = userToFlash.flashId;
          return dealFlashDto;
        })))
        .then(dealFlashDtos => {
          dealFlashDtos.forEach(dealFlashDto => {
            Auditor.uniqueAudit("deal_flash:detail_loaded", {
              parentId,
              flashId: dealFlashDto.flashId,
              placementId,
              userId: dealFlashDto.userId,
              channelId
            });
            this.dealFlashes.push(dealFlashDto);
          });
          this.flashedDealIds.add(parentId);

          // NOTE - preserve current page by pretending we're on the previous page
          // tryNextPageMove will advance by one page when called after deal flash finishes
          if (!this.flashingDeal) {
            this.currentPage--;
            this.tryNextPageMove();
          }
        });
  },

  allowedToViewDealForUser(groupId) {
    let allowedGroupIds = this.dealflashPageModel.get("groupIds");
    if (!allowedGroupIds || allowedGroupIds.length === 0) {
      return true;
    } else {
      allowedGroupIds.forEach(groupId => {
        const childGroups = Groups.getChildGroups(groupId);
        const childGroupIds = childGroups.map(grp => grp.get("id"));
        allowedGroupIds = new Set([...allowedGroupIds, ...childGroupIds]);
      });
      return allowedGroupIds.has(groupId);
    }
  },

  onRefreshChannelCommand(message) {
    const messageChannelId = message.channelId.toString(10);
    const storedChannelId = this.getConfigIdFromLocalStorage();
    if (messageChannelId === storedChannelId) {
      this.refreshWaiting = true;
    }
  },

  getNextDeal() {
    return this.dealFlashes.shift();
  },

  handleFirstPageMove() {
    this.refreshEvery = parseInt(currentQueryString.refreshEvery, 10);
    this.loopCount = 0;
    this.loopStartMillis = Date.now();

    this.currentPage = 0;

    const firstPageModel = this.pages.at(this.currentPage);
    firstPageModel.load();
    this.loadPrevPage();
    this.showPage(firstPageModel);
  },

  handlePrevPageMove() {
    this.currentPage--;
    this.currentPage = (this.currentPage + this.pages.length) % this.pages.length;

    const pageModel = this.pages.at(this.currentPage);
    this.loadPrevPage();
    this.showPage(pageModel);
  },

  tryNextPageMove() {
    const dealFlashWaiting = this.dealFlashes.length > 0;
    if (dealFlashWaiting) {
      window.clearTimeout(this.loopTimeoutId);
      this.flashingDeal = true;
      this.showPage(this.dealflashPageModel);
      return;
    }
    if (this.refreshWaiting) {
      ajax.isCubeAvailable().then(
          response => {
            window.location.reload(true);
          },
          err => {});
      return;
    }

    this.flashingDeal = false;

    let loopWaitMillis = 0;
    if (this.currentPage === this.pages.length - 1) {
      const currentTimeMillis = Date.now();
      const minNextLoopStart = this.loopStartMillis + CUBETV_MIN_LENGTH_MILLIS;
      if (currentTimeMillis < minNextLoopStart) {
        loopWaitMillis = minNextLoopStart - currentTimeMillis;
      }
    }

    const nextPageMove = () => {
      this.currentPage++;
      this.currentPage %= this.pages.length;

      if (this.currentPage === 0) {
        this.loopCount++;
        this.loopStartMillis = Date.now();

        loadLatestUiVersion().then(latestUiVersion => {
          const latestUiVersionId = latestUiVersion.get("id");
          const currentTimeMillis = Date.now();

          const priority = latestUiVersion.get("priority");
          const existingLocalStorageUiVersionUpdate = Immutable.fromJS(store.get("uiVersionUpdate"));
          const newLocalStorageUiVersionUpdate = Immutable.fromJS({
            id: latestUiVersionId,
            priority: priority,
            snoozeUntil: null,
            lastAutoRefreshTriedAt: currentTimeMillis
          });
          if (latestUiVersionId > currentUiVersionInfo.get("id")) {
            if (existingLocalStorageUiVersionUpdate) {
              const hasBeen4HoursSinceLastAutoRefresh = currentTimeMillis >
                  existingLocalStorageUiVersionUpdate.get("lastAutoRefreshTriedAt") + millisFor4Hours;
              if (priority === "CRITICAL" &&
                  (existingLocalStorageUiVersionUpdate.get("id") === latestUiVersionId &&
                      existingLocalStorageUiVersionUpdate.get("priority") === priority)) {
                this.showLatestVersionDialog();
              } else if (priority === "CRITICAL") {
                updateLocalStorageAndRefresh(existingLocalStorageUiVersionUpdate.set("id", latestUiVersionId)
                    .set("lastAutoRefreshTriedAt", currentTimeMillis).set("priority", priority));
              } else if (priority === "BACKGROUND") {
                if (moment.utc(currentUiVersionInfo.get("timestamp")).valueOf()
                    < (currentTimeMillis - millisFor2Weeks)
                    &&
                    hasBeen4HoursSinceLastAutoRefresh) {
                  updateLocalStorageAndRefresh(newLocalStorageUiVersionUpdate);
                }
              } else if (hasBeen4HoursSinceLastAutoRefresh) {
                updateLocalStorageAndRefresh(existingLocalStorageUiVersionUpdate.set("id", latestUiVersionId)
                    .set("lastAutoRefreshTriedAt", currentTimeMillis).set("priority", priority));
              }
            } else {
              if (priority === "BACKGROUND") {
                if (moment.utc(currentUiVersionInfo.get("timestamp")).valueOf() < (currentTimeMillis
                    - millisFor2Weeks)) {
                  updateLocalStorageAndRefresh(newLocalStorageUiVersionUpdate);
                }
              } else {
                updateLocalStorageAndRefresh(newLocalStorageUiVersionUpdate);
              }
            }
          }
        });

        Auditor.audit("bigscreens:loop", {
          channelId: this.getConfigIdFromLocalStorage(),
          channelName: this.channelName
        }, {
          userAgent: true
        });
      }

      if (this.loopCount === this.refreshEvery) {
        ajax.isCubeAvailable().then(
            response => {
              window.location.reload(true);
            },
            err => {});
        return;
      }

      const pageModel = this.pages.at(this.currentPage);
      this.showPage(pageModel);
    };

    window.clearTimeout(this.loopTimeoutId);
    this.loopTimeoutId = window.setTimeout(nextPageMove.bind(this), loopWaitMillis);
  },

  showPage(pageModel) {
    // NOTE the next page must be loaded before we begin showing the channel
    // Because if the first page errors and auto-skips, then the second page must be a state of "loading"
    this.loadNextPage();

    const hasNewsFeedsToShow = this.newsfeeds.get("newsfeeds").length > 0;
    const showNewsticker = pageModel.get("showNewsticker") && hasNewsFeedsToShow;
    const pageView = new Cube19.CubeTv.PageView({
      model: pageModel.set("showNewsticker", showNewsticker).set("channelId", this.channelId)
    });
    this.centreRegion.show(pageView);
    const isDealFlash = pageModel.get("id") === "fullscreen-dealflash";
    const timing = isDealFlash && canShowDealFlashAnimation ? pageModel.get("timing") + 2000 :
        pageModel.get("timing");
    this.queuePageChange(timing);
    this.toggleNewsTicker(showNewsticker);
  },

  loadPrevPage() {
    const prevPageIndex = ((this.currentPage - 1) + this.pages.length) % this.pages.length;
    const prevPage = this.pages.at(prevPageIndex);

    prevPage.load();
  },

  loadNextPage() {
    const nextPageIndex = (this.currentPage + 1) % this.pages.length;
    const nextPage = this.pages.at(nextPageIndex);

    nextPage.load();
  },

  queuePageChange(timing) {
    window.clearTimeout(this.nextPageTimeoutId);
    if (timing > 0) {
      this.nextPageTimeoutId = window.setTimeout(_.bind(this.tryNextPageMove, this), timing);
    }
  },

  toggleNewsTicker(showNewsTicker) {
    if (showNewsTicker) {
      const newstickerConfig = this.newsfeeds;
      if (!this.footerRegion.currentView || this.currentPage === 0) {
        const view = reactComponentToMarionetteComponent(
            <NewsTicker feedIds={newstickerConfig.get("newsfeeds")} />);
        this.footerRegion.show(view);
      }
    } else {
      this.footerRegion.empty();
    }
  },

  showLatestVersionDialog() {
    const dialog = reactComponentToMarionetteComponent(
        <LatestVersionDialog
            loadWithoutCheckingVersion={true}
            priorityOverride="CRITICAL" />);
    this.dialogRegion.show(dialog);
  }
});

const canShowDealFlashAnimation = () => {
  const deviceModel = Auditor.getUserAgent().getDevice().model || "";
  const isIpad = deviceModel.toLowerCase() === "ipad";
  const client = currentClient;
  const clientHasGoodComputer = client.get("showDealFlashAnimation");
  return !isIpad && clientHasGoodComputer;
};

const updateLocalStorageAndRefresh = updatedLocalStorage => {
  store.set("uiVersionUpdate", updatedLocalStorage);
  ajax.isCubeAvailable().then(
      () => {
        window.location.reload(true);
      },
      () => {});
};
