import moment from "moment";

import * as ajax from "js/common/ajax";
import * as kpiRepo from "js/common/repo/backbone/kpi-repo";
import * as timeframeRepo from "js/common/repo/backbone/timeframe-repo";
import * as Users from "js/common/users";
import * as Groups from "js/common/groups";
import * as Kpis from "js/common/kpis";

const path = window.path;

const summary = (kpiId, options) => request("total", kpiId, options);

const report = (kpiId, options) => request("report", kpiId, options);

const trend = (kpiId, options) => request("trend", kpiId, options);

const averageTrend = (kpiId, options) => request("average-trend", kpiId, options);

const targetedTrend = (kpiId, options) => request("targetted-trend", kpiId, options);

const trendTargets = (kpiId, options) => request("trend-targets", kpiId, options);

const leaderboard = (kpiId, options) => request("leaderboard", kpiId, options);

const targetedLeaderboard = (kpiId, options) => request("targetted-leaderboard", kpiId, options);

const request = (url, kpiId, options = {}) => {
  const kpi = kpiRepo.get(kpiId);
  const template = kpi.get("type");

  const params = getParams(options, template.dateType, kpi.getOverrideTimeframe());

  if (params.clientIds.length > 0 && !kpi.get("filterableByClient")) {
    if (options.alwaysAllowClientFilter) {
      delete params.clientIds;
    } else {
      return Promise.reject(new Error("Cannot add Client filter to Metric not filterable by Client"));
    }
  }

  return ajax.post({
    url: path("kpi", kpiId, url),
    json: params,
    returnXhr: options.returnXhr,
    trace: options.trace
  });
};

const testSummary = (editableKpi, columns, options, template, modifiedKpis) => test(
    "total",
    editableKpi,
    columns,
    options,
    template,
    modifiedKpis);

const testReport = (editableKpi, columns, options, template, modifiedKpis) => test(
    "report",
    editableKpi,
    columns,
    options,
    template,
    modifiedKpis);

const testTrend = (editableKpi, columns, options, template, modifiedKpis) => test(
    "trend",
    editableKpi,
    columns,
    options,
    template,
    modifiedKpis);

const testLeaderboard = (editableKpi, columns, options, template, modifiedKpis) => test(
    "leaderboard",
    editableKpi,
    columns,
    options,
    template,
    modifiedKpis);

const test = (action, editableKpi, columns, options, template, modifiedKpis) => {
  const params = getParams(options, template.get("dateType"), editableKpi.get("overrideTimeframe").toJS());
  const url = "kpi/test/" + action;
  return ajax.post({
    url,
    json: {
      dto: Kpis.formatKpi(editableKpi),
      columns,
      params,
      modifiedKpis: modifiedKpis.map(Kpis.formatKpi)
    },
    returnXhr: options.returnXhr,
    trace: options.trace
  });
};

const getParams = (options, dateType, overrideTimeframe) => {
  const params = {...options.params};

  switch (dateType) {
    case "INSTANT":
      params.eventDate = formatDate(timeframeRepo.get("TODAY").get("start"));
      break;
    case "RANGE":
      let start;
      let end;
      if (options.timeframe) {
        let timeframe = options.timeframe;
        if (overrideTimeframe) {
          try {
            timeframe = parseJsonToTimeframe(overrideTimeframe, timeframe);
          } catch(e) {
            throw new Error("Invalid override timeframe");
          }
          if (!timeframe) {
            throw new Error("Invalid override timeframe");
          }
        }
        start = timeframe.get("start");
        end = timeframe.get("end");
      } else if (options.start && options.end) {
        start = options.start;
        end = options.end;
      } else {
        throw new Error("Missing required params: either 'timeframe' or ('start' and 'end')");
      }
      params.startDate = formatDate(start);
      params.endDate = formatDate(end);
      break;
    default:
      console.error("Unexpected dataType: %s", dateType);
      break;
  }

  if (options.qualifierId && options.qualifierType) {
    switch (options.qualifierType) {
      case "GROUP":
        params.groupId = options.qualifierId;
        break;
      case "USER":
        params.userId = options.qualifierId;
        // TODO remove requirement for redundant group parameter
        params.groupId = getUserGroupId(options.qualifierId);
        break;
      default:
        console.error("Unexpected qualifierType: %s", options.qualifierType);
        break;

    }
  } else {
    if (options.userId) {
      params.userId = options.userId;
      params.groupId = options.groupId || getUserGroupId(options.userId);
    } else {
      params.groupId = options.groupId;
    }
  }

  params.clientIds = options.clientIds || [];
  params.anyOfTheseTagIds = options.matchAnyTagIds || [];
  params.allOfTheseTagIds = options.matchAllTagIds || [];
  params.noneOfTheseTagIds = options.excludedTagIds || [];
  params.entityIds = options.entityIds || [];
  params.jobIds = options.jobIds || [];
  params.candidateIds = options.candidateIds || [];
  params.leadIds = options.leadIds || [];
  params.opportunityIds = options.opportunityIds || [];
  params.clientContactIds = options.clientContactIds || [];
  params.placementIds = options.placementIds || [];
  params.dateFromUI = moment().format("YYYY-MM-DD");

  return params;
};

const getUserGroupId = userId => {
  const user = Users.getUser(userId);
  return user ? user.get("groupId") : Groups.getRootGroup().get("id");
};

const parseJsonToTimeframe = (overrideTimeframeJson, originalTimeframe) => {
  const type = overrideTimeframeJson.type.toUpperCase();
  if (type === "NONE") {
    return originalTimeframe;
  } else {
    return timeframeRepo.parse({
      type,
      start: overrideTimeframeJson.startDate,
      end: overrideTimeframeJson.endDate
    });
  }
};

const formatDate = (momentDate) => momentDate.format("YYYY-MM-DD");

export {
  summary,
  report,
  trend,
  averageTrend,
  targetedTrend,
  leaderboard,
  targetedLeaderboard,
  trendTargets,
  testSummary,
  testReport,
  testTrend,
  testLeaderboard
};
