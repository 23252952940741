import React from "react";
import Immutable from "immutable";
import moment from "moment";
import * as TimeAgo from "timeago.js";

import * as GridUtils from "js/dashboards/components/grids/utils";
import {isNumber} from "js/dashboards/components/grids/utils";
import * as Ajax from "js/common/ajax";
import * as Rata from "js/common/utils/remote-data";
import * as DashboardUtils from "js/dashboards/utils";
import * as Formatter from "js/common/utils/formatter";
import {indexByJs} from "js/common/utils/collections";
import useIsMountedRef from "js/common/hooks/use-is-mounted-ref";
import useDimensions from "js/common/utils/use-dimensions";
import {useForceRender} from "js/common/hooks/use-force-render";

import {CustomThemeContext} from "js/common/themes/CustomThemeProvider";
import {AgGridReact} from "ag-grid-react";
import NovoLoading from "js/dashboards/novo-loading";
import GridEditor from "js/dashboards/components/grids/grid-editor";
import * as Overlays from "js/dashboards/components/overlays";
import {TextButton} from "js/common/views/inputs/buttons";
import ErrorMsg from "js/common/views/error";
import CellDialogContent from "js/dashboards/cell-dialog-content";
import ChartTypeSelector from "js/dashboards/components/grids/chart-type-selector";
import * as Users from "js/common/users";

export const setGetterAndFormatterForGroup = (groupOrColumn, labelToColumn) => {
  const key = groupOrColumn.groupId || groupOrColumn.colId;
  groupOrColumn.valueGetter = valueGetterForKey(key);
  groupOrColumn.valueFormatter = valueFormatterForKey(key, labelToColumn);
  if (groupOrColumn.children) {
    groupOrColumn.children = groupOrColumn.children
        .map(childGroup => setGetterAndFormatterForGroup(childGroup, labelToColumn));
  }
  return groupOrColumn;
};

export const valueGetterForKey = key => params => {
  if (params.data) {
    const cell = params.data[key];
    if (cell && cell.value !== "") {
      return cell.value;
    } else {
      return null;
    }
  } else {
    return null;
  }
};

export const valueFormatterForKey = (key, labelToColumn) => params => {
  const cell = params.data[key];
  if (cell) {
    if (isNumber(cell.value)) {
      const column = labelToColumn[key] || {};
      let valueFormat;
      if (cell.currency) {
        valueFormat = "CURRENCY";
      } else {
        valueFormat = GridUtils.dataTypeToValueFormat(column.dataType);
      }
      const formatOptions = {valueFormat};
      return Formatter.format(cell, formatOptions);
    } else {
      return cell.value;
    }
  } else {
    return null;
  }
};

const AsyncServerGrid = React.memo(({
  componentId,
  data,
  title,
  config,
  onConfigChange,
  parentConfig,
  isEditing,
  enableCharts,
  onRequestDialog,
  onRequestReload,
  queueDataLoad,
  componentTypeToTemplate,
  componentLoadId,
  downloadFnRef
}) => {
  const [dimensionRef, {height}] = useDimensions();

  const columns = Rata.getValue(data).get("columns");

  const kpiIdsForCurrentColumns = Rata.getValue(data).get("kpiIdsForCurrentColumns");
  React.useEffect(() => {
    if (!Immutable.is(config.get("kpiIds").toSet(), kpiIdsForCurrentColumns.toSet())) {
      onRequestReload();
    }
  }, [config, kpiIdsForCurrentColumns, onRequestReload]);

  const labelToColumn = React.useMemo(() => indexByJs(c => c.label, columns), [columns]);
  const columnLabels = React.useMemo(() => new Set(columns.map(c => c.label)), [columns]);
  const customAggFuncsToRegisterWithGrid = React.useMemo(
      () => {
        const customAggFuncs = new Set(columns.flatMap(c => c.customAggregationFunctions || []));
        const nameToNothing = {};
        customAggFuncs.forEach(aggFunc => {
          nameToNothing[aggFunc] = () => {};
        });
        return nameToNothing;
      }, [columns]);

  const columnStates = config.get("columnStates", Immutable.List());

  const columnDefs = React.useMemo(
      () => GridUtils.columnsToDefs(
          columns,
          columnStates.toJS(),
          valueGetterForKey,
          valueFormatterForKey),
      [columns, columnStates]);

  const isMountedRef = useIsMountedRef();

  const chartElRef = React.useRef(null);

  const {theme} = React.useContext(CustomThemeContext);
  const {gridTheme, chartTheme} = GridUtils.getThemes(theme.themeId);

  const lastTopLevelAggParamsRef = React.useRef();
  const firstTopLevelAggParamsRef = React.useRef();
  const forceReloadNextRequest = React.useRef(false);
  const [latestTopLevelRequestWrapper, setLatestTopLevelRequestWrapper] = React.useState(Rata.wrapInitialValue(null));
  // eslint-disable-next-line no-unused-vars
  const [latestExpandRowRequestWrapper, setLatestExpandRowRequestWrapper] = React.useState(Rata.wrapInitialValue(null));

  const dataSourceRef = React.useRef({});
  const gridRef = React.useRef();
  const [chartId, setChartId] = React.useState();
  const [disableChart, setDisableChart] = React.useState(false);
  const [needsReload, setNeedsReload] = React.useState(false);

  dataSourceRef.current.getRows = React.useCallback(
      params => {
        const aggParams = params.request;
        aggParams.dataCols = config.get("columnStates")
            .filter(c => c.get("hide") === false && !c.get("aggFunc"))
            .map(c => {
              return {
                id: c.get("colId"), displayName: c.get("colId")
              };
            })
            .toArray();
        GridUtils.addRequiredColumns(aggParams, columns);

        const loadingTopLevelData = aggParams.groupKeys.length === 0;
        if (loadingTopLevelData) {
          if (firstTopLevelAggParamsRef.current === null) {
            firstTopLevelAggParamsRef.current = Immutable.fromJS({aggParams, config});
          } else {
            const paramsImmutable = Immutable.fromJS({aggParams, config});
            const originalParamsImmutable = firstTopLevelAggParamsRef.current;
            const configHasChanged = !Immutable.is(paramsImmutable, originalParamsImmutable);

            if (configHasChanged) {
              // NOTE we don't want to auto reload because the user may want to make several changes before reloading
              // therefore we clear the data to avoid confusion
              setNeedsReload(true);
              params.success([]);
              return;
            }
          }
          lastTopLevelAggParamsRef.current = aggParams;

          const isPivoting = config.getIn(["gridProps", "pivotMode"], true);
          if (aggParams.rowGroupCols.length === 0
              && (aggParams.pivotCols.length === 0 || !isPivoting)) {
            params.success([]);
            if (columns.length > 0) {
              let message;
              if (isPivoting) {
                message = "Big Data Grid needs at least 1 field in 'Row Groups' or 'Column Labels'";
              } else {
                message = "Big Data Grid needs at least 1 field in 'Row Groups'";
              }
              setLatestTopLevelRequestWrapper(x => Rata.toError(x, message));
            }
            return;
          }

          const shouldForceReload = forceReloadNextRequest.current;
          forceReloadNextRequest.current = false;
          loadLatestRequest(columns, config, aggParams, shouldForceReload)
              .then(request => pollUntilRequestComplete(
                  setLatestTopLevelRequestWrapper,
                  request.get("id"),
                  componentLoadId,
                  originalLoadId => isMountedRef.current && originalLoadId === componentLoadId))
              .then(request => loadDataForRequest(request.get("id")))
              .then(data => {
                setNeedsReload(false);
                params.columnApi.setPivotResultColumns(data.secondaryColumnGroups.map(x => setGetterAndFormatterForGroup(
                    x,
                    labelToColumn)));
                params.success({rowData: data.rows});
                params.columnApi.autoSizeAllColumns();

                // Re-apply the column sorting
                if (config.get("colOrder")) {
                  params.columnApi.applyColumnState({
                    state: config.get("colOrder").toJS(),
                    defaultState: {sort: null}
                  });
                }

                // Remount the chart with the latest data
                if (enableCharts && config.getIn(["gridProps", "pivotMode"]) !== false) {
                  GridUtils.reMountChartOnThemeChange(config, gridRef, chartTheme, chartElRef, false);
                }

              })
              .catch(err => {
                if (isMountedRef.current) {
                  let message;
                  if (err.responseJSON?.message) {
                    message = err.responseJSON.message;
                  } else if (err.message) {
                    message = err.message;
                  } else {
                    message = "Something went wrong";
                  }
                  params.fail();
                  setLatestTopLevelRequestWrapper(x => Rata.toError(x, message));
                }
              });
        } else {
          const expandRowAggParams = {...aggParams, requestId: Rata.getValue(latestTopLevelRequestWrapper).get("id")};
          loadLatestRequest(columns, config, expandRowAggParams)
              .then(request => pollUntilRequestComplete(
                  setLatestExpandRowRequestWrapper,
                  request.get("id"),
                  componentLoadId,
                  originalLoadId => isMountedRef.current && originalLoadId === componentLoadId))
              .then(request => loadDataForRequest(request.get("id")))
              .then(data => {
                // NOTE if there are any group keys then we're loading a subset of data
                //    therefore we must not set the secondary columns
                //    because a subset will not contain all secondary columns in the response
                params.success({rowData: data.rows});
                params.columnApi.autoSizeAllColumns();
              })
              .catch(err => {
                params.fail();
                setLatestExpandRowRequestWrapper(x => Rata.toError(x, err.message));
              });
        }
      },
      [
        columns,
        enableCharts,
        labelToColumn,
        config,
        chartTheme,
        componentLoadId,
        isMountedRef,
        latestTopLevelRequestWrapper,
        gridRef]);

  const handleRequestReload = React.useCallback(() => {
    setLatestTopLevelRequestWrapper(x => Rata.toLoading(x));
    setLatestExpandRowRequestWrapper(Rata.wrapInitialValue(null));
    firstTopLevelAggParamsRef.current = null;
    forceReloadNextRequest.current = true;

    // NOTE the grid may have been unmounted at this point
    if (gridRef.current && gridRef.current.api) {
      gridRef.current.api.refreshServerSide({purge: true});
    }
  }, [gridRef]);

  const onColumnPivotModeChanged = React.useCallback(e => {
    onConfigChange(componentId, config => config.setIn(["gridProps", "pivotMode"], e.columnApi.isPivotMode()));
    if (e.columnApi.isPivotMode() === false) {
      handleRequestReload();
    }
  }, [componentId, handleRequestReload, onConfigChange]);

  const handleCellClick = React.useCallback(e => {
    queueDataLoad(() => {
      const rowGroupKeys = GridUtils.getRowGroupKeys(e.node);

      const pivotKeys = Immutable
          .List(e.column.colId.split("|"))
          .filter(c => c.indexOf("ag-Grid-AutoColumn-") !== 0)
          .butLast()
          .toArray();

      if (rowGroupKeys.length > 0 || (pivotKeys.length > 0 && config.getIn(["gridProps", "pivotMode"], true))) {
        const rowGroupColumns = e.columnApi.getRowGroupColumns().slice(0, rowGroupKeys.length);
        const pivotColumns = e.columnApi.getPivotColumns().slice(0, pivotKeys.length);
        const aggParams = {
          dataCols: columns.map(c => ({
            id: c.label,
            displayName: c.label
          })),
          groupKeys: rowGroupKeys.concat(pivotKeys),
          rowGroupCols: rowGroupColumns.concat(pivotColumns).map(c => ({
            id: c.colId,
            displayName: c.colId
          })),
          pivotCols: [],
          valueCols: []
        };

        GridUtils.addRequiredColumns(aggParams, columns);

        const dialogId = "server-grid-cell-dialog-" + Math.random();
        const filterTitle = GridUtils.getFilterTitle(
            rowGroupColumns.map(c => c.colId),
            rowGroupKeys,
            pivotColumns.map(c => c.colId),
            pivotKeys);
        onRequestDialog(
            <div style={{display: "flex", alignItems: "center", justifyContent: "center", height: "100%"}}>
              <NovoLoading />
            </div>,
            {id: dialogId, title: filterTitle});

        // TODO PAYBILL use async server grid here instead if row count behind cell is extremely high
        //    only server side knows row count in AggregatedCell, info is removed before reaching UI
        //    how to pass this back without too many wrappers?
        return Ajax
            .post({
              url: "reporting/server-grid", json: {
                reqParams: GridUtils.configToReqParams(config), kpiIds: config.get("kpiIds").toArray(), aggParams
              }
            })
            .then(
                res => {
                  onRequestDialog(
                      <CellDialogContent
                          fileName={title ? title + "  " + filterTitle : filterTitle}
                          rows={res.rows}
                          columns={columns}
                          parentConfig={parentConfig}
                          parentGridConfig={config}
                          queueDataLoad={queueDataLoad}
                          onRequestDialog={onRequestDialog}
                          componentTypeToTemplate={componentTypeToTemplate} />,
                      {id: dialogId, requireExistingId: true});
                },
                error => {
                  let message;
                  if (error.responseJSON && error.responseJSON.message) {
                    message = "Error loading data behind cell: " + error.responseJSON.message;
                  } else {
                    message = "Error loading data behind cell";
                  }
                  onRequestDialog(message, {id: dialogId, requireExistingId: true});
                });
      } else {
        return Promise.resolve();
        // TODO PAYBILL could potentially show a click-through for just the row that was clicked on
        //  useful for seeing extra columns without reloading whole grid
        //  also useful if click-through contains more views like txn master report
      }
    });
  }, [title, columns, config, parentConfig, onRequestDialog, queueDataLoad, componentTypeToTemplate]);

  const onChartCreated = React.useCallback((event) => {
    // Set chart ID, this can be used with gridRef to get stuff for our actual chart
    setChartId(event.chartId);
    onConfigChange(componentId, config => config.setIn(["chart", "id"], chartId));
  }, [componentId, chartId, onConfigChange]);

  const onChartOptionsChanged = React.useCallback((event) => {
    // After the ag-grid chart type is changed also set it in config so that it persists
    const chartType = event.chartType;
    onConfigChange(componentId, config => config.setIn(["chart", "type"], chartType));
  }, [componentId, onConfigChange]);

  React.useEffect(() => {
    const api = gridRef && gridRef.current && gridRef.current.api;
    // Remount the chart with the new theme
    if (api && enableCharts && config.getIn(["gridProps", "pivotMode"]) !== false) {
      GridUtils.reMountChartOnThemeChange(config, gridRef, chartTheme, chartElRef, false);
    }
  }, [theme, config, enableCharts, chartElRef, gridRef, chartTheme]);

  React.useEffect(() => {
    const api = gridRef && gridRef.current && gridRef.current.api;
    if (api && enableCharts && config.getIn(["gridProps", "pivotMode"]) !== false) {
      GridUtils.openChartToolPanelsOnEdit(isEditing, gridRef);
    }
  }, [config, enableCharts, isEditing, gridRef]);

  const onFirstDataRendered = React.useCallback((event) => {
    // Mount a chart on first render if we are not in pivot mode
    if (enableCharts && config.getIn(["gridProps", "pivotMode"]) !== false) {
      GridUtils.mountChart(config, chartTheme, chartElRef.current, gridRef.current.api, false);
    }
  }, [chartTheme, config, enableCharts]);

  React.useEffect(() => {
    // Mount a chart if we don't have one,
    // this is used when we load up a grid which is not in pivot mode and then switch to pivot mode
    // a.k.a loaded AFTER the firstDataRendered event above
    if (enableCharts && config.getIn(["gridProps", "pivotMode"]) !== false && !chartId) {
      GridUtils.mountChart(config, chartTheme, chartElRef.current, gridRef.current.api, false);
    }

    // re-apply column sorting
    if (config.get("colOrder")) {
      if (gridRef && gridRef.current && gridRef.current.api) {
        gridRef.current.columnApi.applyColumnState({
          state: config.get("colOrder").toJS(),
          defaultState: {sort: null}
        });
      }
    }

    const groupedRowsCount = columnDefs && columnDefs.filter(c => c.rowGroup === true).length;
    const columnLabelCount = columnDefs && columnDefs.filter(c => c.pivot === true).length;
    // disable the chart if we have multiple rowGroups or Column Labels
    setDisableChart(groupedRowsCount > 1 || columnLabelCount > 1);
  }, [config, chartId, chartTheme, enableCharts, columnDefs, gridRef, chartElRef]);

  const gridStateSyncFns = React.useMemo(
      () => GridUtils.keepGridStateInSync(componentId, columnLabels, onConfigChange),
      [componentId, columnLabels, onConfigChange]);

  const {gridWidth, chartWidth} = GridUtils.getGridAndChartWidth(config);
  const chartEl = <div
      ref={chartElRef}
      className={theme.themeId === "light" ? "ag-theme-alpine" : "ag-theme-alpine-dark"}
      style={{width: chartWidth, height: title ? height - 30 : height}} />;
  const valueFormatterCache = {};
  const getDownloadObject = () => {
    const hasPermission = Users.currentHasPermission("EXPORT_FILE");
    let tooltipText;
    if (hasPermission) {
      tooltipText = "Download";
    } else {
      tooltipText = "Ask an admin user for the 'Export To File' permission to download this data";
    }
    return (
        {
          enabled: hasPermission,
          tooltip: tooltipText,
          downloadFn: () => {
            if (gridRef.current) {
              gridRef.current.api.exportDataAsExcel({
                fileName: GridUtils.getExportFileName(config, title),
                processCellCallback(params) {
                  const key = params.column.getColDef().showRowGroup || params.column.getColId();
                  let valueFormatter = valueFormatterCache[key];
                  if (!valueFormatter) {
                    valueFormatter = valueFormatterForKey(key, labelToColumn);
                    valueFormatterCache[key] = valueFormatter;
                  }
                  // NOTE params differs from what we expect based on other grid param uses
                  // these assignments fill in the gaps
                  params.data = params.node.data || {};
                  return valueFormatter(params);
                }
              });
            }
          }
        });
  };

  downloadFnRef.current = () => getDownloadObject();

  const overlayStyle = {
    padding: "0 3rem",
    display: "flex",
    alignItems: "center",
    top: 0,
    right: 0,
    position: "absolute",
    background: theme.themeId === "light" ? "rgba(255, 255, 255, 1)" : "rgba(39, 39, 49, 1)",
    width: chartWidth,
    height: title ? (height + 100) - 30 : height + 100,
    textAlign: "center",
    justifyContent: "center"
  };

  return <div style={{width: "100%", height: "100%", display: "flex", flexFlow: "column"}}>
    <RequestLoadingPercentageOverlay latestTopLevelRequestWrapper={latestTopLevelRequestWrapper} />
    <div style={{padding: "0 1rem", display: "flex"}}>
      {enableCharts && isEditing && <ChartTypeSelector
          componentId={componentId}
          config={config}
          onConfigChange={onConfigChange} />}
      {config.get("isClickThrough") && <GridUtils.DownloadButton onClick={() => getDownloadObject().downloadFn()} />}
      <ReloadButton
          enableCharts={enableCharts}
          latestTopLevelRequestWrapper={latestTopLevelRequestWrapper}
          onRequestReload={handleRequestReload} />
      <TimeSinceLoadMessage latestTopLevelRequestWrapper={latestTopLevelRequestWrapper} />
      {Rata.hasError(latestTopLevelRequestWrapper) && <ErrorMsg text={Rata.getError(latestTopLevelRequestWrapper)} />}
    </div>
    <div style={{display: "flex", height: "100%", overflow: "hidden"}}>
      <div ref={dimensionRef} style={{flex: 1, width: gridWidth, height: "100%"}}>
        <div className={gridTheme} style={{display: "flex", flexDirection: "column", height}}>
          {needsReload && <div
              style={{
                ...overlayStyle,
                top: "auto",
                bottom: 0,
                height,
                left: 0,
                right: "auto",
                zIndex: 1,
                width: isEditing ? `calc(${gridWidth} - 281px)` : gridWidth
              }}>Once you've finished making changes, load the latest data again.</div>}
          <AgGridReact
              {...GridUtils.defaultGridProps}
              {...config.get("gridProps", Immutable.Map()).toJS()}
              {...gridStateSyncFns}
              ref={gridRef}
              enableCharts={enableCharts}
              onChartCreated={onChartCreated}
              onChartOptionsChanged={onChartOptionsChanged}
              customChartThemes={GridUtils.customChartThemes}
              chartThemes={GridUtils.chartThemes}
              sideBar={isEditing ? "columns" : false}
              rowModelType="serverSide"
              onFirstDataRendered={onFirstDataRendered}
              suppressServerSideInfiniteScroll={true}
              maxConcurrentDatasourceRequests={1}
              getMainMenuItems={() => ["pinSubMenu", "autoSizeThis", "autoSizeAll"]}
              serverSideDatasource={dataSourceRef.current}
              aggFuncs={customAggFuncsToRegisterWithGrid}
              columnDefs={columnDefs}
              headerHeight={34}
              onColumnPivotModeChanged={onColumnPivotModeChanged}
              defaultGroupOrderComparator={GridUtils.groupOrderComparator}
              onCellClicked={handleCellClick} />
        </div>
      </div>
      {chartEl}
      {config.getIn(["gridProps", "pivotMode"]) === false && (config.getIn(["chart", "position"])
              === "FULL"
              || config.getIn(["chart", "position"])
              === "LEFT") &&
          <div style={overlayStyle}>
            Pivot charts require pivot mode to be enabled.
          </div>
      }
      {disableChart && (config.getIn(["chart", "position"])
              === "FULL"
              || config.getIn(["chart", "position"])
              === "LEFT") &&
          <div style={overlayStyle}>
            Charting is not available for multiple row groups or column labels
          </div>
      }
    </div>
  </div>;
});

const TimeSinceLoadMessage = React.memo(({latestTopLevelRequestWrapper}) => {
  const forceRender = useForceRender();
  React.useEffect(() => {
    const id = setInterval(() => {
      forceRender();
    }, 10000);
    return () => clearInterval(id);
  }, [forceRender]);
  if (Rata.isLoaded(latestTopLevelRequestWrapper)
      && Rata.getValue(latestTopLevelRequestWrapper).get("status") === "COMPLETED") {
    const request = Rata.getValue(latestTopLevelRequestWrapper);
    let agoStr;
    if (request) {
      agoStr = TimeAgo.format(moment
          .utc(Rata.getValue(latestTopLevelRequestWrapper).get("receivedTimestamp"))
          .toDate());
    } else {
      agoStr = "";
    }
    return <span
        style={{
          paddingLeft: "0.5rem",
          display: "inline-block",
          position: "relative",
          zIndex: 1
        }}>Data loaded {agoStr}</span>;
  } else {
    return null;
  }
});

const RequestLoadingPercentageOverlay = React.memo(({latestTopLevelRequestWrapper}) => {
  if (Rata.isLoaded(latestTopLevelRequestWrapper)
      && Rata.getValue(latestTopLevelRequestWrapper).get("status") === "PROCESSING") {
    const formatOptions = {
      valueFormat: "PERCENT",
      decimalPlaces: 0
    };
    const formattedPercentLoaded = Formatter.format(
        {value: Rata.getValue(latestTopLevelRequestWrapper).get("percentLoaded")},
        formatOptions);
    return <Overlays.Loading overlayStyle={{opacity: 0.5}}>
      Loading {formattedPercentLoaded}
    </Overlays.Loading>;
  } else {
    return null;
  }
});

const ReloadButton = React.memo(({
  enableCharts,
  latestTopLevelRequestWrapper,
  onRequestReload
}) => {
  return <TextButton
      icon="refresh"
      disabled={Rata.isLoading(latestTopLevelRequestWrapper)
          || (Rata.isLoaded(latestTopLevelRequestWrapper)
              && Rata.getValue(latestTopLevelRequestWrapper).get("status") === "PROCESSING")}
      style={{
        marginLeft: enableCharts ? "0.5rem" : 0,
        display: "inline-block",
        position: "relative",
        zIndex: 1,
        minHeight: 36,
        height: "100%"
      }}
      label="Load latest data"
      type="inverted"
      onClick={onRequestReload} />;
});

const delay = (pData, delayInMillis) => new Promise((resolve) => {
  setTimeout(() => resolve(pData), delayInMillis);
});

const pollUntilRequestComplete = (onChange, requestId, originalLoadId, shouldKeepLoading) => {
  return loadRequestForId(requestId).then(request => {
    if (shouldKeepLoading(originalLoadId)) {
      onChange(x => Rata.toLoaded(x, request));
      if (request.get("status") === "COMPLETED") {
        return request;
      } else if (request.get("status") === "FAILED") {
        throw new Error(request.get("errorMessage"));
      } else if (request.get("status") === "PROCESSING") {
        return delay(null, 500)
            .then(() => pollUntilRequestComplete(onChange, requestId, originalLoadId, shouldKeepLoading));
      } else {
        return request;
      }
    } else {
      throw new Error("loading cancelled, probably due to component unmount");
    }
  });
};

const loadRequestForId = requestId => {
  return Ajax
      .get({url: "reporting/server-grid/async/request/" + requestId})
      .then(parseServerSideRequest);
};

const loadLatestRequest = (columns, config, aggParams, forceReload = false) => {
  const reqParams = GridUtils.configToReqParams(config);

  const requestBody = {
    reqParams, aggParams, kpiIds: config.get("kpiIds")
  };

  let url;
  if (forceReload) {
    url = "reporting/server-grid/async/queue";
  } else {
    url = "reporting/server-grid/async/latest-request";
  }
  return Ajax
      .post({url, json: requestBody})
      .then(parseServerSideRequest);
};

const parseServerSideRequest = httpResponse => {
  // NOTE transforming from wrapped server request (req + status + progress) into a single UI request map
  const res = Immutable.fromJS(httpResponse);
  return res.get("request")
      .set("status", res.get("status"))
      .set("percentLoaded", res.get("percentLoaded"));
};

const loadDataForRequest = requestId => Ajax
    .get({url: "reporting/server-grid/async/data-for-request/" + requestId});

export default Immutable.fromJS({
  type: "AsyncServerGrid",
  label: "Big Data Grid",
  getDefaultData: () => Immutable.Map({columns: [], kpiIdsForCurrentColumns: Immutable.Set()}),
  getReactComponent: () => AsyncServerGrid,
  getEditorReactComponent: () => GridEditor,
  getTitle: component => component.get("title"),
  canFullScreen: true,
  canDownload: true,
  layout: {
    min: {width: 12, height: 12},
    max: {width: 48, height: 24},
    init: {width: 24, height: 16}
  },
  applyParentConfig: (parentConfig, dataConfig) => DashboardUtils.applyInheritanceForKeys(parentConfig, dataConfig),
  load: component => {
    const config = component.get("dataConfig");
    const requestBody = {
      params: GridUtils.configToReqParams(config),
      kpiIds: config.get("kpiIds", Immutable.List())
    };
    return Ajax
        .post({url: "reporting/server-grid/columns", json: requestBody})
        .then(columns => Immutable.Map({
          columns,
          kpiIdsForCurrentColumns: config.get("kpiIds").toSet()
        }));
  }
});
