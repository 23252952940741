import * as Immutable from "immutable";

import * as Ajax from "js/common/ajax";
import * as KpiRepo from "js/common/repo/backbone/kpi-repo";
import * as TimeframeRepo from "js/common/repo/backbone/timeframe-repo";
import * as Groups from "js/common/groups";

const path = window.path;

const updateChannel = channel => {
  const url = path("carousels/", channel.get("id"));
  const json = channel.delete("cid").toJS();
  return Ajax.put({url, json}).then(response => Immutable.fromJS(response));
};

const createChannel = channel => {
  const url = "carousels";
  const json = channel.delete("cid").toJS();
  return Ajax.post({url, json}).then(response => Immutable.fromJS(response));
};

const getChannel = id => {
  const url = "carousels/" + id;
  return Ajax.get({url}).then(response => parseChannel(Immutable.fromJS(response)));
};

const deleteChannel = id => {
  const url = path("carousels/", id);
  return Ajax.del({url});
};

const loadChannels = () => Ajax
    .get({url: "carousels"})
    .then(response => Immutable.fromJS(response).map(parseChannel));

const parseChannel = channel => {
  channel = channel.has("cid") ? channel : channel.set("cid", Math.random());
  channel = channel.updateIn(["json", "pages"], slides => slides.map(parseSlide));
  return channel;
};

const parseSlide = slide => {
  slide = slide.has("cid") ? slide : slide.set("cid", Math.random());
  const layoutId = slide.get("layout").get("id");
  if (layoutId === "LeftBigRightSmall") {
    slide = parseLeaderboardSlide(slide);
  } else if (layoutId === "LeftBigRight4") {
    slide = parseTrendSlide(slide);
  }
  const defaultPageTitle = Immutable.fromJS({
    "title": "",
    "subTitle": "",
    "timeframeId": ""
  });
  slide = slide.has("pageTitle") ? slide : slide.set("pageTitle", defaultPageTitle);
  return slide;
};

const parseLeaderboardSlide = slide => {
  slide = slide.updateIn(["layout", "left", 0], parseLeaderboard);
  slide = slide.updateIn(["layout", "right"], right => {
    right = right.update(0, parseTopPerformers);
    right = right.update(1, parseCompanyOverview);
    return right;
  });
  return slide;
};

const parseLeaderboard = config => {
  if (!config.has("rankedKpiId")) {
    const rankedKpiId = KpiRepo.getByTemplateName(config.get("rankedKpiTemplateName")).id;
    config = config.set("rankedKpiId", rankedKpiId);
    config = config.delete("rankedKpiTemplateName");
  }
  config = config.delete("rankedKpiTemplateName");

  if (!config.has("otherKpiId") && config.has("otherKpiTemplateName")) {
    const otherKpiId = KpiRepo.getByTemplateName(config.get("otherKpiTemplateName")).id;
    config = config.set("otherKpiId", otherKpiId);
    config = config.delete("otherKpiTemplateName");
  }
  config = config.delete("otherKpiTemplateName");

  if (config.has("rankedKpiId") && config.has("otherKpiId") && !config.has("rankedKpiColumnHeading")) {
    config = config.set("rankedKpiColumnHeading", "Amount");
  }
  if (config.has("rankedKpiId") && config.has("otherKpiId") && !config.has("otherKpiColumnHeading")) {
    config = config.set("otherKpiColumnHeading", "Deals");
  }

  if (!config.has("totalUsers")) {
    config = config.set("totalUsers", 20);
  }
  config = populateTagIds(config);
  config = config.delete("maxErrorsAllowed");
  config = populateGroupId(config);
  return config;
};

const parseTopPerformers = config => {
  return config.update("kpis", kpiConfigs => {
    return kpiConfigs
        .take(3)
        .map(k => convertKpiTemplateNameToId(k, "kpiTemplateName"))
        .map(populateTagIds)
        .map(k => {
          if (!k.get("displayName")) {
            const timeframe = TimeframeRepo.get(k.get("timeframeId"));
            const kpi = KpiRepo.get(k.get("kpiId"));
            const displayName = `${kpi.get("name")} ${timeframe.get("shortName") || timeframe.get("name")}`;
            return k.set("displayName", displayName);
          } else {
            return k;
          }
        });
  });
};

const parseCompanyOverview = config => {
  config = config.update("kpis", kpiConfigs => {
    kpiConfigs = kpiConfigs.take(2);
    kpiConfigs = kpiConfigs.map(k => convertKpiTemplateNameToId(k, "kpiTemplateName"));
    kpiConfigs = kpiConfigs.map(populateKpiDisplayName);
    return kpiConfigs;
  });
  config = populateGroupId(config);
  config = populateTagIds(config);
  return config;
};

const parseTrendSlide = slide => {
  slide = slide.updateIn(["layout", "left", 0], parseTrend);
  slide = slide.updateIn(["layout", "right", 0], parseMiniCubes);
  return slide;
};

const parseTrend = config => config
    .update("kpis", kpiConfigs => {
      kpiConfigs = kpiConfigs.map(c => {
        if (!c.has("cid")) {
          c = c.set("cid", Math.random());
        }
        return c;
      });
      kpiConfigs = kpiConfigs.map(c => convertKpiTemplateNameToId(c, "templateName"));
      kpiConfigs = kpiConfigs.map(populateGroupId);
      kpiConfigs = kpiConfigs.map(populateTagIds);

      kpiConfigs = kpiConfigs.sortBy(c => c.get("order"));
      kpiConfigs = kpiConfigs.map(c => c.delete("order"));
      kpiConfigs = kpiConfigs.map(c => c.delete("padUptoToday"));
      kpiConfigs = kpiConfigs.map(c => c.delete("color"));
      kpiConfigs = kpiConfigs.map(c => c.delete("targetColor"));
      kpiConfigs = kpiConfigs.map(c => {
        if (!c.has("targetKey")) {
          c = c.set("targetKey", "Target");
        }
        return c;
      });
      return kpiConfigs;
    })
    .delete("timeframeType");

const parseMiniCubes = config => config
    .update("kpis", kpiConfigs => {
      kpiConfigs = kpiConfigs.take(4);
      kpiConfigs = kpiConfigs.map(k => convertKpiTemplateNameToId(k, "templateName"));
      kpiConfigs = kpiConfigs.map(populateGroupId);
      kpiConfigs = kpiConfigs.map(populateTagIds);
      return kpiConfigs;
    });

const convertKpiTemplateNameToId = (kpiConfig, key) => {
  if (!kpiConfig.has("kpiId") && kpiConfig.has(key)) {
    const kpiId = KpiRepo.getByTemplateName(kpiConfig.get(key)).id;
    kpiConfig = kpiConfig.set("kpiId", kpiId);
  }
  return kpiConfig.delete(key);
};

const populateGroupId = config => {
  if (!config.has("groupId") || config.get("groupId") === "root") {
    config = config.set("groupId", Groups.getRootGroup().get("id"));
  }
  return config;
};

const populateTagIds = config => {
  if (!config.has("matchAnyTagIds")) {
    config = config.set("matchAnyTagIds", Immutable.List());
  }
  if (!config.has("matchAllTagIds")) {
    config = config.set("matchAllTagIds", Immutable.List());
  }
  if (!config.has("excludedTagIds")) {
    config = config.set("excludedTagIds", Immutable.List());
  }
  return config;
};

const populateKpiDisplayName = kpiConfig => {
  if (!kpiConfig.has("displayName")) {
    const kpi = KpiRepo.get(kpiConfig.get("kpiId"));
    const timeframe = TimeframeRepo.get(kpiConfig.get("timeframeId"));
    const displayName = `${kpi.get("name")} ${timeframe.get("shortName") || timeframe.get("name")}`;
    kpiConfig = kpiConfig.set("displayName", displayName);
  }
  return kpiConfig;
};

export {
  updateChannel,
  createChannel,
  deleteChannel,
  getChannel,
  loadChannels,
  parseChannel
};