import React from "react";
import reactCreateClass from "create-react-class";
import * as Immutable from "immutable";
import moment from "moment";
import PureRenderMixin from "react-addons-pure-render-mixin";

import * as Auditor from "js/common/auditer";
import * as Ajax from "js/common/ajax";
import * as Strings from "js/common/utils/strings";
import * as Kpis from "js/common/kpis";
import * as timeframeRepo from "js/common/repo/backbone/timeframe-repo";
import currentClient from "js/common/repo/backbone/current-client";
import {indexBy, selectKeys} from "js/common/utils/collections";

import pure from "js/common/views/pure";
import ErrorMsg from "js/common/views/error";
import Hint from "js/admin/common/hint";
import Checkbox from "js/common/views/inputs/checkbox";
import {Layout} from "js/common/views/foundation-column-layout";
import ReactTextareaAutocomplete from "@webscopeio/react-textarea-autocomplete";
import Paginator from "js/job-pipelines/paginator";
import Select from "js/common/views/inputs/immutable-react-select";
import SimpleDataTable from "js/common/views/tables/simple-data-table";
import LoadingSpinner from "js/common/views/loading-spinner";
import TimeframePicker from "js/common/views/inputs/timeframe-picker/react-timeframe-picker";
import Tabs from "js/common/views/tabs";
import ReportingPivotTable from "js/reporting/reporting-pivot-table";
import TraceViewer from "js/common/views/trace-viewer";

import saveAsCsv from "js/common/save-as-csv";
import {Chip, TextField, Dialog, DialogContent, DialogActions} from "@mui/material";
import {TextButton} from "js/common/views/inputs/buttons";
import {parseToPivotData} from "js/common/pivot-utils";
import {createSelector} from "js/admin/data-explorer/selectors";
import * as Formatter from "js/common/utils/formatter";
import {CustomThemeContext} from "js/common/themes/CustomThemeProvider";
import * as auditor from "js/common/auditer";
import {stringToJoinPath} from "js/admin/kpis/edit-kpis/utils";

const columnsSelector = state => state.config.get("availableColumns");
const idToWildcardColumnSelector = state => state.config.get("idToWildcardColumn");
const configSelector = state => state.config;
const idToNamedEntitySelector = state => state.idToNamedEntity;

const dbColumnsSelector = createSelector(
    columnsSelector,
    idToWildcardColumnSelector,
    configSelector,
    (columns, idToWildcardColumn, config) => columns.filter(c => {
      const isMetabaseColumn = !c.get("entityColumnId");
      const wildcardPaths = selectKeys(idToWildcardColumn, config.get("selectedWildcardColumnIds"))
          .valueSeq()
          .map(c => c.get("joinPathStr"))
          .toSet();
      const matchesWildcard = wildcardPaths.includes(c.get("joinPathStr"));
      return isMetabaseColumn && !matchesWildcard;
    }));

const dbColumnOptionsSelector = createSelector(
    dbColumnsSelector,
    dbColumns => dbColumns.map(c => Immutable.Map({
      label: c.get("displayLabel"),
      value: c.get("tempId")
    })));

const columnOptionsSelector = createSelector(
    columnsSelector,
    columns => columns.map(c => Immutable.Map({
      label: c.get("displayLabel"),
      value: c.get("tempId")
    })));

const wildcardColumnOptionsSelector = createSelector(
    idToWildcardColumnSelector,
    idToWildcardColumn => idToWildcardColumn
        .valueSeq()
        .sortBy(c => c.get("sortScore"))
        .map(c => Immutable.Map({
          label: c.get("displayLabel"),
          value: c.get("tempId")
        })));

const entityColumnsSelector = createSelector(
    columnsSelector,
    columns => columns.filter(c => c.get("entityColumnId")));

const entityColumnOptionsSelector = createSelector(
    entityColumnsSelector,
    entityColumns => entityColumns.map(c => Immutable.Map({
      label: c.get("displayLabel"),
      value: c.get("tempId")
    })));

const entityOptionsSelector = createSelector(
    idToNamedEntitySelector,
    idToNamedEntity => idToNamedEntity
        .valueSeq()
        .filter(namedEntity => namedEntity.get("validForRoot"))
        .sortBy(namedEntity => namedEntity.get("labelWithTables"))
        .map(namedEntity => {
          return Immutable.Map({
            label: namedEntity.get("labelWithTables"),
            value: namedEntity.get("entity")
          });
        }));

const TraceViewerTab = () => {
  const [str, setStr] = React.useState("");
  const [traceId, setTraceId] = React.useState(null);
  // TODO add easy way to run metrics?
  //    metric picker
  //    action picker
  //    json field for params
  //    pick trace level
  //    auto populate viewer with trace id on run
  return (<div>
    <div style={{padding: "1rem", display: "flex", alignItems: "baseline"}}>
      <TextField
          variant="standard"
          style={{marginRight: "1rem", width: 300}}
          label="Trace ID"
          value={str}
          onChange={e => setStr(e.target.value)} />
      <TextButton label="View trace" onClick={() => setTraceId(str)} />
    </div>
    {traceId && <TraceViewer traceId={traceId} />}
  </div>);
};

const TabTitle = ({icon, text}) => <span><i className={`fa fa-${icon}`} /> {text}</span>;

const Tabbed = () => {
  const [index, setIndex] = React.useState(0);
  const {theme} = React.useContext(CustomThemeContext);
  const tabs = [
    {
      title: <TabTitle text="Database" />,
      content: <DataExplorerTab theme={theme} />
    }, {
      title: <TabTitle text="Trace Viewer" />,
      content: <TraceViewerTab />
    }];

  return (<Tabs
      selectedIndex={index}
      onChangeTab={setIndex}
      tabs={tabs}
      saveTabStateOnChange={false}
      containerStyle={{margin: 3}} />);
};

export default Tabbed;

const DataExplorerTab = reactCreateClass({

  getInitialState() {
    return {
      loadingInitialData: false,
      idToTable: Immutable.Map(),
      idToNamedEntity: Immutable.Map(),

      nextTempColumnId: 1,
      columnSlugToTempId: Immutable.Map(),
      loadingColumns: false,

      config: Immutable.fromJS({
        slowFilter: "",
        timeframe: timeframeRepo.getDefaultForClient().getRawJson(),
        deletedType: "ALL",

        selectedStartingEntity: null,
        entityPaths: Immutable.Set(),

        availableColumns: Immutable.Set(),
        idToWildcardColumn: Immutable.Map(),

        selectedWildcardColumnIds: Immutable.Set(),
        selectedMetabaseColumnIds: Immutable.Set(),
        selectedEntityColumnIds: Immutable.Set(),

        selectedOrderByColumnId: null,
        sortDescending: true
      }),

      headerRow: null,
      dataRows: null,
      isExportDialogOpen: false,
      currentResultId: null,
      idToResult: Immutable.Map()
    };
  },

  componentDidMount() {
    this.setState({loadingInitialData: true});
    Promise
        .all([loadTables(), loadEntities()])
        .then(([tables, entities]) => {
          const groupingEntityToTables = tables.groupBy(t => t.get("groupingEntity"));
          entities = entities.map(namedEntity => {
            const tables = groupingEntityToTables.get(namedEntity.get("entity"), Immutable.List());
            const tablesStr = tables.map(displayLabelForTable).join(", ");
            const justTableName = !namedEntity.get("isRenamed") && tables.count() === 1;
            const labelWithTables = justTableName
                ? tablesStr
                : namedEntity.get("name") + " (" + tablesStr + ")";
            const label = justTableName ? tablesStr : namedEntity.get("name");
            return namedEntity
                .set("labelWithTables", labelWithTables)
                .set("label", label);
          });
          this.setState({
            loadingInitialData: false,
            idToTable: indexBy(e => e.get("tableEntity"), tables),
            idToNamedEntity: indexBy(e => e.get("entity"), entities)
          });
          auditor.audit("data-explorer-admin:loaded");
        });
  },

  updateConfig(changes, setStateCallback = () => {}) {
    const {config} = this.state;
    const updatedConfig = config.merge(Immutable.Map(changes));
    this.setState({
      config: updatedConfig
    }, setStateCallback);
  },

  gotoResult(resultId) {
    this.setState({currentResultId: resultId});
  },

  deleteResult(resultId, setStateCallback = () => {}) {
    let newCurrentResultId;
    const newIdToResult = this.state.idToResult.remove(resultId);
    if (resultId === this.state.currentResultId) {
      newCurrentResultId = newIdToResult.keySeq().max() || 0;
    } else {
      newCurrentResultId = this.state.currentResultId;
    }
    this.setState({
      currentResultId: newCurrentResultId,
      idToResult: newIdToResult
    }, setStateCallback);
  },

  createNewResult(setStateCallback = () => {}) {
    const newResultId = (this.state.idToResult.keySeq().max() || 0) + 1;
    const newResult = blankResult(newResultId, this.state.config);
    this.setState({
      currentResultId: newResultId,
      idToResult: this.state.idToResult.set(newResultId, newResult)
    }, setStateCallback);
  },

  updateResult(resultId, changes, setStateCallback = () => {}) {
    const result = this.state.idToResult.get(resultId);
    const updatedResult = result.merge(Immutable.Map(changes));
    this.setState({
      idToResult: this.state.idToResult.set(resultId, updatedResult)
    }, setStateCallback);
  },

  updateCurrentResult(changes, setStateCallback = () => {}) {
    this.updateResult(this.state.currentResultId, changes, setStateCallback);
  },

  render() {
    const visibleTimeframes = timeframeRepo
        .getAll()
        .filter(t => t.get("visible") && !t.get("isDeleted"));

    const {config} = this.state;
    const selectedNamedEntity = this.state.idToNamedEntity.get(config.get("selectedStartingEntity"));
    const {theme} = this.props;
    // todo use raw react select, not immutable wrapper, have selector for js options
    //  to reduce immutable->js->immutable conversions
    // could also look into other select libs (react-select-me although it appears unmaintained)

    return (
        <div style={sectionSpacingStyle}>
          <Layout small={[4, 8]} rowStyle={sectionSpacingStyle} floatLastColumnLeft={true}>
            <TimeframePicker
                isDisabled={!selectedNamedEntity || !selectedNamedEntity.get("filterableByDate")}
                timeframes={visibleTimeframes}
                timeframe={timeframeRepo.parse(config.get("timeframe").toJS())}
                onChange={this.handleTimeframeChange} />
            <Select
                options={entityOptionsSelector(this.state)}
                placeholder={this.state.loadingInitialData ? "Loading..." : "Root Entity"}
                selectedValue={config.get("selectedStartingEntity")}
                onChange={this.handleSelectStartingEntity}
                isMulti={false}
                isClearable={false}
                isSearchable={true} />
          </Layout>
          <Layout allSmall={12} columnStyle={sectionSpacingStyle} floatLastColumnLeft={true}>
            <EntityPathPickers
                entityPaths={config.get("entityPaths")}
                onChange={this.handleEntityPathsChange}
                idToNamedEntity={this.state.idToNamedEntity} />
          </Layout>
          <Layout allSmall={12} rowStyle={sectionSpacingStyle} floatLastColumnLeft={true}>
            <Select
                options={entityColumnOptionsSelector(this.state)}
                isDisabled={!config.get("selectedStartingEntity")}
                closeMenuOnSelect={false}
                placeholder={this.state.loadingColumns ? "Loading..." : "Entity Columns"}
                selectedValues={config.get("selectedEntityColumnIds")}
                onChange={this.handleChangeEntityColumns}
                isMulti={true}
                isClearable={true}
                isSearchable={true} />
          </Layout>
          <Layout allSmall={12} rowStyle={sectionSpacingStyle} floatLastColumnLeft={true}>
            <Select
                options={wildcardColumnOptionsSelector(this.state)}
                isDisabled={!config.get("selectedStartingEntity")}
                closeMenuOnSelect={false}
                placeholder={this.state.loadingColumns ? "Loading..." : "All Columns In Table(s)"}
                selectedValues={config.get("selectedWildcardColumnIds")}
                onChange={this.handleChangeWildcardColumns}
                isMulti={true}
                isClearable={true}
                isSearchable={true} />
          </Layout>
          <Layout allSmall={12} rowStyle={sectionSpacingStyle} floatLastColumnLeft={true}>
            <Select
                options={dbColumnOptionsSelector(this.state)}
                isDisabled={!config.get("selectedStartingEntity")}
                closeMenuOnSelect={false}
                placeholder={this.state.loadingColumns ? "Loading..." : "Database Columns"}
                selectedValues={config.get("selectedMetabaseColumnIds")}
                onChange={this.handleChangeDbColumns}
                isMulti={true}
                isClearable={true}
                isSearchable={true} />
          </Layout>
          <div style={{...sectionSpacingStyle, display: "flex"}}>
            <div style={{padding: "0 0.5rem 0 1rem"}}>
              <Checkbox
                  label="Distinct?"
                  checked={config.get("distinct")}
                  onCheck={this.handleDistinctChange} />
            </div>
            <div style={{width: "220px", padding: "0 1rem"}}>
              <Select
                  disabled={!selectedNamedEntity || !selectedNamedEntity.get("filterableByDeleted")}
                  options={deletedOptions}
                  selectedValue={config.get("deletedType")}
                  onChange={this.handleDeletedOptionChange}
                  isMulti={false}
                  isClearable={false}
                  isSearchable={false} />
            </div>
            <div style={{flexGrow: 1, padding: "0 1rem"}}>
              <Select
                  options={columnOptionsSelector(this.state)}
                  disabled={!config.get("selectedStartingEntity")}
                  placeholder={this.state.loadingColumns ? "Loading..." : "Order By Column"}
                  selectedValue={config.get("selectedOrderByColumnId")}
                  onChange={this.handleOrderByChange}
                  isMulti={false}
                  isClearable={false}
                  isSearchable={true} />
            </div>
            <div style={{padding: "0 1rem"}}>
              <Checkbox
                  label="Descending?"
                  checked={config.get("sortDescending")}
                  onCheck={this.handleSortChange} />
            </div>
          </div>
          <Layout allSmall={12} rowStyle={sectionSpacingStyle} floatLastColumnLeft={true}>
            <AutoCompleteSqlFilter
                value={config.get("slowFilter")}
                onChange={this.handleSlowFilterChange}
                disabled={!config.get("selectedStartingEntity")}
                columns={config.get("availableColumns")} />
          </Layout>
          <div style={sectionSpacingStyle}>
            <TextButton
                label="Load"
                disabled={!config.get("selectedStartingEntity")
                    || (config.get("selectedMetabaseColumnIds").isEmpty()
                        && config.get("selectedEntityColumnIds").isEmpty()
                        && config.get("selectedWildcardColumnIds").isEmpty())
                    || this.isLoadingResult()}
                onClick={this.handleLoadClick} />
          </div>
          <ResultChips
              theme={theme}
              onClick={this.gotoResult}
              onDelete={this.deleteResult}
              idToResult={this.state.idToResult}
              idToNamedEntity={this.state.idToNamedEntity}
              currentResultId={this.state.currentResultId} />
          {this.renderResults()}
        </div>
    );
  },

  handleLoadClick() {
    this.createNewResult(() => this.loadAndSetDataForConfig(this.state.config));
  },

  handleOrderByChange(id) {
    this.updateConfig({selectedOrderByColumnId: id});
  },

  handleChangeEntityColumns(ids) {
    this.updateConfig({selectedEntityColumnIds: ids.toSet()});
  },

  handleChangeWildcardColumns(ids) {
    this.updateConfig({selectedWildcardColumnIds: ids.toSet()});
  },

  handleChangeDbColumns(ids) {
    this.updateConfig({selectedMetabaseColumnIds: ids.toSet()});
  },

  handleDistinctChange(e, isChecked) {
    this.updateConfig({distinct: isChecked});
  },

  handleSortChange(e, isChecked) {
    this.updateConfig({sortDescending: isChecked});
  },

  handleSlowFilterChange(slowFilter) {
    this.updateConfig({slowFilter});
  },

  handleTimeframeChange(timeframe) {
    this.updateConfig({
      timeframe: Immutable.fromJS(timeframe.getRawJson())
    });
  },

  handleDeletedOptionChange(deletedType) {
    this.updateConfig({deletedType});
  },

  isLoadingResult() {
    if (this.state.currentResultId) {
      const result = this.state.idToResult.get(this.state.currentResultId);
      const offset = result.get("offset");
      return result.get("offsetToLoading").get(offset);
    } else {
      return false;
    }
  },

  renderResults() {
    // todo maintain state of rendered pivot tables? just display none instead of purging?
    const noResults = !this.state.currentResultId
        || !this.state.idToResult.has(this.state.currentResultId)
        || this.state.idToResult.isEmpty();
    if (this.state.errorMessage) {
      return <ErrorMsg text={this.state.errorMessage} />;
    } else if (noResults) {
      return <Hint>No results</Hint>;
    } else {
      const result = this.state.idToResult.get(this.state.currentResultId);
      const offset = result.get("offset");
      if (this.isLoadingResult()) {
        return <LoadingSpinner />;
      } else if (result.get("queryFailed")) {
        return (
            <div>
              <p>{result.get("failureMessage")}</p>
              <pre>{result.get("offsetToSql").get(offset)}</pre>
            </div>);
      } else {
        const data = result.get("offsetToData").get(offset);
        if (data) {
          const tabs = [
            {
              title: <TabTitle icon="table" text="Data Table" />,
              content:
                  <div>
                    <PureTable
                        onCellClick={this.handleCellClick}
                        onDownloadClick={this.openExportDialog}
                        rows={data.rows}
                        columns={data.columns} />
                    <ExportDialog
                        open={this.state.isExportDialogOpen}
                        onClick={this.handleDownloadClick}
                        onRequestClose={this.closeExportDialog} />
                    <Dialog
                        style={dialogZIndexFix}
                        fullWidth={true}
                        open={!!this.state.selectedData}
                        onClose={this.handleCloseDialog}>
                      <DialogContent>
                        <pre tabIndex={-1} onFocus={this.handleDialogContentFocus}>
                          {formatData(this.state.selectedData)}
                        </pre>
                      </DialogContent>
                    </Dialog>
                  </div>
            }, {
              title: <TabTitle icon="line-chart" text="Slice & Dice" />,
              content:
                  <div className="table-dark">
                    <ReportingPivotTable
                        key={this.state.currentResultId}
                        config={result.get("pivotConfig")}
                        data={result.get("offsetToPivotData").get(offset)}
                        onPivotTableRefresh={this.handlePivotTableRefresh} />
                  </div>
            }];
          return (
              <div>
                <Paginator
                    itemsPerPage={result.get("rowsPerPage")}
                    currentPage={offset / result.get("rowsPerPage")}
                    totalItems={result.get("totalRowCount")}
                    onChange={this.handleChangePage} />
                <TextButton
                    label="Switch to config"
                    onClick={() => {
                      this.updateConfig(result.get("config"), this.loadAndSetColumns);
                    }} />
                <Tabs
                    containerStyle={{marginTop: "1rem"}}
                    saveTabStateOnChange={true}
                    selectedIndex={result.get("tabIndex")}
                    onChangeTab={this.handleChangeTab}
                    tabs={tabs} />
                <pre style={{width: "100%", overflowX: "auto", overflowY: "hidden"}}>
                {result.get("offsetToSql").get(offset)}
              </pre>
              </div>);
        } else {
          return <Hint>No data found for offset</Hint>;
        }
      }
    }
  },

  handleDownloadClick(reason) {
    const result = this.state.idToResult.get(this.state.currentResultId);
    const {headerRow, dataRows} = this.state;
    const offset = result.get("offset");

    Auditor.audit(
        "data-explorer:export-csv",
        {
          reason,
          offset,
          config: result.get("config").delete("availableColumns").delete("idToWildcardColumn").toJS(),
          sql: result.get("offsetToSql").get(offset)
        });

    const config = result.get("config");
    const selectedColumns = getSelectedColumns(
        result.get("config"),
        config.get("availableColumns"),
        config.get("idToWildcardColumn"));
    for (let i = 0; i < selectedColumns.count(); i++) {
      const explorerColumn = selectedColumns.get(i);
      if (explorerColumn.get("mayContainPii")) {
        for (let j = 0; j < dataRows.length; j++) {
          dataRows[j][i] = "[redacted pii for export]";
        }
      }
    }

    const rows = [headerRow].concat(dataRows);
    const filename = currentClient.get("id") + " " + currentClient.get("name")
        + " - " + result.get("config").get("selectedStartingEntity")
        + " - " + moment().format("YYYY-MM-DD HH:mm:ss")
        + ".csv";
    saveAsCsv(rows, filename);

    this.closeExportDialog();
  },

  openExportDialog(headerRow, dataRows) {
    this.setState({isExportDialogOpen: true, headerRow, dataRows});
  },

  closeExportDialog() {
    this.setState({isExportDialogOpen: false});
  },

  handleDialogContentFocus(e) {
    const el = e.target;
    const range = document.createRange();
    range.selectNodeContents(el);
    const selection = window.getSelection();
    selection.removeAllRanges();
    selection.addRange(range);
  },

  handleCloseDialog() {
    this.setState({selectedData: null});
  },

  handleChangeTab(index) {
    this.updateCurrentResult({tabIndex: index});
  },

  handleCellClick(cell) {
    this.setState({selectedData: cell});
  },

  handleEntityPathsChange(entityPathStrs, pathLengthToReplace) {
    const {config} = this.state;
    const oldEntityPaths = config.get("entityPaths");

    const selectedEntityPaths = entityPathStrs.map(pathStr => Immutable.List(pathStr.split(",")));
    const oldPathsWithSameLength = oldEntityPaths.filter(path => path.count() === pathLengthToReplace);
    const deletedPaths = oldPathsWithSameLength.subtract(selectedEntityPaths);

    const newEntityPaths = oldEntityPaths
        .filter(path => path.count() !== pathLengthToReplace)
        .filter(path => {
          if (path.count() > pathLengthToReplace) {
            const dependsOnDeletedPath = deletedPaths.some(deletedPath => pathStartsWith(path, deletedPath));
            return !dependsOnDeletedPath;
          } else {
            return true;
          }
        })
        .union(selectedEntityPaths);

    this.updateConfig({entityPaths: newEntityPaths}, this.loadAndSetColumns);
  },

  handleSelectStartingEntity(selectedStartingEntity) {
    const entityPaths = Immutable.Set.of(Immutable.List.of(selectedStartingEntity));
    this.updateConfig({
      selectedStartingEntity,
      entityPaths,
      slowFilter: "",
      selectedWildcardColumnIds: Immutable.Set(),
      selectedMetabaseColumnIds: Immutable.Set(),
      selectedEntityColumnIds: Immutable.Set()
    }, this.loadAndSetColumns);
  },

  loadAndSetColumns() {
    const {config} = this.state;
    let {columnSlugToTempId, nextTempColumnId} = this.state;
    this.setState({
      loadingColumns: true
    });
    Kpis.loadColumnsForEntityPaths(config.get("entityPaths"))
        .then(columns => {
          const uniquePathStrs = columns.map(c => c.get("joinPathStr")).toSet();
          const wildcardColumns = uniquePathStrs
              .map(pathStr => Immutable.Map({label: "*", joinPathStr: pathStr}))
              .map(c => {
                const columnSlug = c.get("joinPathStr") + c.get("label") + c.get("entityColumnId", "");
                const existingTempId = columnSlugToTempId.get(columnSlug);
                let tempId;
                if (existingTempId) {
                  tempId = existingTempId;
                } else {
                  tempId = nextTempColumnId;
                  nextTempColumnId++;
                  columnSlugToTempId = columnSlugToTempId.set(columnSlug, tempId);
                }
                return c
                    .set("tempId", tempId)
                    .set("displayLabel", displayLabelForColumn(c, this.state.idToTable))
                    .set("sortScore", sortScoreForColumn(c));
              });

          const availableColumns = columns
              .map(c => {
                const columnSlug = c.get("joinPathStr") + c.get("label") + c.get("entityColumnId", "");
                const existingTempId = columnSlugToTempId.get(columnSlug);
                let tempId;
                if (existingTempId) {
                  tempId = existingTempId;
                } else {
                  tempId = nextTempColumnId;
                  nextTempColumnId++;
                  columnSlugToTempId = columnSlugToTempId.set(columnSlug, tempId);
                }
                return c
                    .set("tempId", tempId)
                    .set("displayLabel", displayLabelForColumn(c, this.state.idToTable))
                    .set("sortScore", sortScoreForColumn(c));
              })
              .sortBy(c => c.get("sortScore"));

          const entityCount = 1 + config.get("entityPaths").reduce((acc, path) => acc + path.count() - 1, 0);
          const {selectedWildcardColumnIds} = this.state;
          const oneTablePerEntity = entityCount === wildcardColumns.count();
          let newSelectedWildcardColumnIds;
          if (oneTablePerEntity) {
            newSelectedWildcardColumnIds = wildcardColumns.map(c => c.get("tempId")).toSet();
          } else {
            newSelectedWildcardColumnIds = config.get("selectedWildcardColumnIds");
          }

          this.updateConfig({
            selectedWildcardColumnIds: newSelectedWildcardColumnIds,
            idToWildcardColumn: indexBy(c => c.get("tempId"), wildcardColumns),
            availableColumns
          });

          this.setState({
            nextTempColumnId,
            columnSlugToTempId,
            loadingColumns: false
          });
        });
  },

  loadAndSetDataForConfig(config) {
    const {currentResultId} = this.state;
    const result = this.state.idToResult.get(currentResultId);
    const offset = result.get("offset");

    this.setState({errorMessage: null});
    this.updateCurrentResult({
      offsetToLoading: result.get("offsetToLoading").set(offset, true)
    });

    const {filter, tempIdToColumn} = parseFilterForServer(
        config.get("slowFilter"),
        indexBy(c => c.get("tempId"), config.get("availableColumns")));

    const selectedColumns = getSelectedColumns(
        config,
        config.get("availableColumns"),
        config.get("idToWildcardColumn"));
    // todo available columns should be indexed by id and maintain order using an attribute
    const orderByColumn = config.get("availableColumns")
        .find(c => c.get("tempId") === config.get("selectedOrderByColumnId"));
    loadData(
        selectedColumns,
        orderByColumn,
        config.get("sortDescending"),
        filter,
        tempIdToColumn,
        config.get("timeframe"),
        offset,
        config.get("distinct"),
        config.get("deletedType")).then(
        ({queryFailed, failureMessage, data, totalRowCount, rowsPerPage, sql}) => {
          const latestResult = this.state.idToResult.get(currentResultId);
          if (queryFailed) {
            this.updateResult(
                currentResultId,
                {
                  rowsPerPage,
                  failureMessage,
                  queryFailed,
                  offsetToLoading: latestResult.get("offsetToLoading").set(offset, false),
                  offsetToSql: latestResult.get("offsetToSql").set(offset, sql),
                  totalRowCount
                });
          } else {
            this.updateResult(
                currentResultId,
                {
                  rowsPerPage,
                  offsetToLoading: latestResult.get("offsetToLoading").set(offset, false),
                  offsetToSql: latestResult.get("offsetToSql").set(offset, sql),
                  offsetToData: latestResult.get("offsetToData").set(offset, data),
                  offsetToPivotData: latestResult.get("offsetToPivotData")
                      .set(offset, parseToPivotData(data.columns, data.rows)),
                  totalRowCount
                });
          }
        },
        errorResponse => {
          this.deleteResult(currentResultId);
          this.setState({errorMessage: errorResponse.responseJSON.message});
        });
  },

  handleChangePage(newPage) {
    const result = this.state.idToResult.get(this.state.currentResultId);
    const newOffset = newPage * result.get("rowsPerPage");
    this.updateCurrentResult(
        {offset: newOffset},
        () => {
          const data = result.get("offsetToData").get(newOffset);
          if (!data) {
            this.loadAndSetDataForConfig(result.get("config"));
          }
        });
  },

  handlePivotTableRefresh(pivotTableConfig) {
    const newPivotConfig = this.state.idToResult
        .getIn([this.state.currentResultId, "pivotConfig"])
        .set("renderer", pivotTableConfig.get("rendererName"))
        .set("aggregator", pivotTableConfig.get("aggregatorName"))
        .set("cellValue", pivotTableConfig.get("vals").first())
        .set("columns", pivotTableConfig.get("cols"))
        .set("rows", pivotTableConfig.get("rows"));
    this.updateResult(this.state.currentResultId, {
      pivotConfig: newPivotConfig
    });
  }

});

const AutoCompleteSqlFilter = reactCreateClass({
  mixins: [PureRenderMixin],

  getInitialState() {
    return {
      internalValue: this.props.value
    };
  },

  setInternalValue(newInternalValue, force) {
    if (newInternalValue !== this.state.internalValue) {
      this.setState({internalValue: newInternalValue});
      if (this.componentRef && force) {
        // NOTE appears to be a bug currently, value cannot be controlled in react standard way
        // scanning github issues indicates react upgrade will likely fix it
        this.componentRef.setState({value: newInternalValue});
      }
    }
  },

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.clearExistingTimeout();
    this.setInternalValue(nextProps.value, true);
  },

  render() {
    return <ReactTextareaAutocomplete
        placeholder="Enter arbitrary sql to filter your data (type ':' for database columns, type ';' for entity columns)"
        dropdownStyle={{zIndex: 999}}
        minChar={0}
        disabled={this.props.disabled}
        ref={ref => this.componentRef = ref}
        value={this.state.internalValue}
        onChange={this.handleChange}
        onBlur={this.handleBlur}
        loadingComponent={LoadingSpinner}
        trigger={{
          ":": {
            dataProvider: token => {
              const matchingColumns = getMatchingColumns(
                  this.props.columns.filter(c => !c.get("entityColumnId")),
                  token);
              return matchingColumns.take(12).toArray();
            },
            allowWhitespace: true,
            output: c => "[" + c.get("tempId") + " " + c.get("label") + "]",
            component: ({entity}) => <div style={{color: "#111"}}>{entity.get("displayLabel")}</div>
          },
          ";": {
            dataProvider: token => {
              const matchingColumns = getMatchingColumns(
                  this.props.columns.filter(c => c.get("entityColumnId")),
                  token);
              return matchingColumns.take(12).toArray();
            },
            allowWhitespace: true,
            output: c => "[" + c.get("tempId") + " " + c.get("label") + "]",
            component: ({entity}) => <div style={{color: "#111"}}>{entity.get("displayLabel")}</div>
          }
        }} />;
  },

  handleChange(e) {
    const newValue = e.target.value;
    this.setInternalValue(newValue, false);

    this.clearExistingTimeout();
    const timeoutId = setTimeout(() => {
      this.props.onChange(newValue);
    }, 1000);
    this.setState({timeoutId});
  },

  handleBlur() {
    this.clearExistingTimeout();
    this.props.onChange(this.state.internalValue);
  },

  clearExistingTimeout() {
    if (this.state.timeoutId) {
      clearTimeout(this.state.timeoutId);
    }
  }

});

const getSelectedColumns = (config, availableColumns, idToWildcardColumn) => availableColumns.filter(c => {
  const matchesEntityColumn = config.get("selectedEntityColumnIds").includes(c.get("tempId"));
  const matchesMetabaseColumn = config.get("selectedMetabaseColumnIds").includes(c.get("tempId"));
  const wildcardPaths = selectKeys(idToWildcardColumn, config.get("selectedWildcardColumnIds"))
      .valueSeq()
      .map(c => c.get("joinPathStr"))
      .toSet();
  const matchesWildcard = wildcardPaths.includes(c.get("joinPathStr"));
  return matchesEntityColumn || matchesMetabaseColumn || (matchesWildcard && !c.get("entityColumnId"));
});

const getMatchingColumns = (columns, token) => {
  if (token === "") {
    return columns.sortBy(c => c.get("sortScore"));
  } else {
    return columns
        .map(c => c.set("matchScore", Strings.similarityScore(c.get("displayLabel"), token)))
        .filter(c => c.get("matchScore") > 0)
        .sortBy(c => c.get("matchScore"))
        .reverse();
  }
};

const ResultChips = pure(({theme, idToResult, currentResultId, idToNamedEntity, onClick, onDelete}) => {
  const chips = idToResult
      .valueSeq()
      .sortBy(r => r.get("id"))
      .map(result => {
        const entityName = idToNamedEntity.get(result.get("config").get("selectedStartingEntity")).get("label");
        const isActive = result.get("id") === currentResultId;
        let color;
        let labelColor;
        if (isActive) {
          color = theme.palette.primary.main;
          labelColor = theme.palette.text.inverted;
        } else if (result.get("queryFailed")) {
          color = "#c47272";
          labelColor = "#eee";
        } else {
          color = theme.palette.background.light;
          labelColor = theme.palette.textColor;
        }
        const label = <span style={{color: labelColor, fontSize: 14}}>{result.get("id")} {entityName}</span>;
        return (
            <Chip
                key={result.get("id")}
                label={label}
                style={{background: color, marginRight: "0.5rem", marginBottom: "0.5rem"}}
                onClick={() => onClick(result.get("id"))}
                onDelete={() => onDelete(result.get("id"))} />);
      });
  return <div style={{marginBottom: "1rem", display: "flex", flexWrap: "wrap"}}>{chips}</div>;
});

const deletedOptions = Immutable.fromJS([
  {value: "NOT_DELETED", label: "Not Deleted Only"},
  {value: "DELETED", label: "Deleted Only"},
  {value: "ALL", label: "Show All Rows"}
]);

const EntityPathPickers = pure(({entityPaths, onChange, idToNamedEntity}) => {
  const maxEntityPathLength = entityPaths.map(entityPath => entityPath.count()).max();
  let pickers = [];
  for (let i = 0; i < maxEntityPathLength; i++) {
    const pathsUptoIndex = entityPaths
        .filter(entityPath => entityPath.count() >= (i + 1))
        .map(entityPath => entityPath.take(i + 1));

    const potentialPaths = pathsUptoIndex.flatMap(path => {
      const entityType = path.last();
      const entity = idToNamedEntity.get(entityType);
      return entity.get("joinsTo").map(e => path.push(e));
    });

    const options = potentialPaths
        .map(path => Immutable.Map({
          label: path.skip(1).map(e => idToNamedEntity.get(e).get("label")).join(" > "),
          value: path.join(",")
        }))
        .sortBy(option => option.get("label"));

    const selectedValues = entityPaths
        .filter(entityPath => entityPath.count() >= (i + 2))
        .map(entityPath => entityPath.take(i + 2))
        .map(path => path.join(","));

    pickers.push(<Select
        key={i}
        options={options}
        placeholder="Joins"
        selectedValues={selectedValues}
        onChange={pathStrs => onChange(pathStrs, i + 2)}
        closeOnSelect={false}
        isMulti={true}
        isClearable={true}
        isSearchable={true} />);
  }
  if (pickers.length > 0) {
    return <div>{pickers}</div>;
  } else {
    return null;
  }
});

const dialogZIndexFix = {zIndex: 9000};
const sectionSpacingStyle = {marginBottom: "1rem"};

const formatData = val => {
  if (!val) {
    return val;
  }

  const mightBeJson = typeof (val) === "string" && (val.indexOf("{") === 0 || val.indexOf("[") === 0);
  if (mightBeJson) {
    try {
      return JSON.stringify(JSON.parse(val), null, 2);
    } catch (e) {
      return val;
    }
  } else {
    return val;
  }
};

const PureTable = pure(({
  columns,
  ...props
}) => (
    <SimpleDataTable
        initialSortBy={null}
        maxTableHeight={800}
        downloadable={true}
        parentHandlingDownload={true}
        columns={columns.map(parseColumn)}
        {...props} />));

const blankResult = (id, config) => Immutable.fromJS({
  id,
  config,
  tabIndex: 0,
  offsetToData: {},
  offsetToPivotData: {},
  pivotConfig: defaultPivotConfig,
  offsetToSql: {},
  offsetToLoading: {},
  offset: 0,
  totalRowCount: 0,
  rowsPerPage: 1
});

const pathStartsWith = (path, prefix) => Immutable.is(path.slice(0, prefix.count()), prefix);

const countInStr = (str, char) => {
  let count = 0;
  for (let i = 0; i < str.length; i++) {
    if (str.charAt(i) === char) {
      count++;
    }
  }
  return count;
};

const sortScoreForColumn = c => {
  if (c.get("entityColumnId")) {
    const pathStr = c.get("joinPathStr");
    const label = c.get("label");

    const isId = label.indexOf("ID") !== -1;
    const isUser = Strings.endsWith("USER", pathStr);
    const isGroup = Strings.endsWith("GROUP", pathStr);
    const isUserName = isUser && !isId;
    const isGroupName = isGroup && !isId;
    const pathLength = countInStr(pathStr, ",") + 1 + (isUserName || isGroupName ? -1 : 0);
    const largePathLength = pathLength * 50;

    const isCreatedDate = label.indexOf("Created Date") !== -1;

    if (isId && !(isUserName || isGroupName)) {
      return largePathLength + 1;
    } else if (isCreatedDate) {
      return largePathLength + 2;
    } else if (isUser) {
      return largePathLength + 3;
    } else if (isGroup) {
      return largePathLength + 4;
    } else {
      return largePathLength + 5;
    }
  } else {
    const pathStr = c.get("joinPathStr");
    const pathScore = countInStr(pathStr, ",");
    if (c.get("label") === "*") {
      return pathScore;
    } else {
      const indexOfDataEntity = pathStr.indexOf("_DATA");
      const isAdditionalData = indexOfDataEntity !== -1 && pathStr.indexOf("ENTITY") !== 0;
      return pathScore + (isAdditionalData ? indexOfDataEntity : 0);
    }
  }
};

const displayLabelForColumn = (c, idToTable) => {
  if (c.get("entityColumnId")) {
    return c.get("label");
  } else {
    const tableNames = stringToJoinPath(c.get("joinPathStr"))
        .map(segment => {
          const tableEntity = segment.get("tableEntity");
          const context = segment.get("contextFromParent");
          const table = idToTable.get(tableEntity);
          return displayLabelForTable(table) + (context !== "NONE" ? "+" + context : "");
        });
    return tableNames.join(",") + "." + stripBackticks(c.get("label"));
  }
};

const displayLabelForTable = table => {
  const dbPart = table.get("database") === "DataWarehouse" ? "" : stripBackticks(table.get("database")) + ".";
  return dbPart + stripBackticks(table.get("name"));
};

const removeUiColumnFields = c => c.remove("tempId").remove("sortScore").remove("displayLabel");

const stripBackticks = str => str.replaceAll("`", "");

const uiPlaceholderPattern = /\[(\d+)\s[^\[^\]]+\]/g;
const parseFilterForServer = (filter, tempIdToColumn) => {
  const tempIds = [];
  const newFilter = filter.replace(
      uiPlaceholderPattern,
      (match, tempId) => {
        tempId = parseInt(tempId);
        tempIds.push(tempId);
        return ":" + tempId + ":";
      });
  return {
    filter: newFilter,
    tempIdToColumn: selectKeys(tempIdToColumn, Immutable.Set(tempIds)).map(removeUiColumnFields)
  };
};

const ExportDialog = reactCreateClass({

  mixins: [PureRenderMixin],

  getInitialState() {
    return {
      exportReason: ""
    };
  },

  render() {
    return <Dialog
        style={dialogZIndexFix}
        fullWidth={true}
        open={this.props.open}
        onClose={this.props.onRequestClose}>
      <DialogContent>
        <TextField
            variant="standard"
            fullWidth={true}
            label="Reason for export to csv"
            value={this.state.exportReason}
            onChange={e => this.setState({exportReason: e.target.value})} />
      </DialogContent>
      <DialogActions>
        <TextButton
            label="Download"
            disabled={!this.state.exportReason}
            onClick={() => {
              this.props.onClick(this.state.exportReason);
              this.setState({exportReason: ""});
            }} />
      </DialogActions>
    </Dialog>;
  }

});

// NOTE not converting rows of js data to immutable because SimpleDataTable expects normal JS data
//  no point converting to immutable only to revert it immediately after
const loadData = (
    selectColumns,
    orderByColumn,
    sortDescending,
    slowFilterWithPlaceholders,
    slowFilterTempIdToColumn,
    timeframe,
    offset,
    distinct,
    deletedType
) => Ajax.put({
  url: "data-explorer/run",
  json: {
    offset,
    start: timeframe.get("start"),
    end: timeframe.get("end"),
    deletedType,
    distinct,
    slowFilterWithPlaceholders,
    slowFilterTempIdToColumn,
    orderByColumns: orderByColumn ? [removeUiColumnFields(orderByColumn)] : [],
    sortDescending,
    selectColumns: selectColumns.sortBy(c => c.get("sortScore")).map(removeUiColumnFields)
  }
});

const loadEntities = () => Ajax
    .get({url: "data-explorer/entities"})
    .then(res => Immutable.fromJS(res));

const loadTables = () => Ajax
    .get({url: "data-explorer/table-names"})
    .then(res => Immutable.fromJS(res));

const parseColumn = column => {
  return {
    label: stripBackticks(column.label),
    sortMapper: cell => cell.value,
    displayMapper: cell => cell.value,
    ...mappersByColumnType[column.dataType]
  };
};

const defaultPivotConfig = Immutable.fromJS({
  columns: [],
  rows: [],
  aggregator: "Count All",
  renderer: "Table",
  total: {
    row: true,
    column: true
  }
});

const mappersByColumnType = {
  STRING: {
    commonMapper: cell => cell.value,
    displayMapper: cell => cell,
    sortMapper: null
  },
  PERCENTAGE: {
    displayMapper: cell => Formatter.format(cell, {valueFormat: "PERCENT", decimalPlaces: 2})
  },
  CURRENCY: {
    displayMapper: cell => Formatter.format(cell, {decimalPlaces: 2})
  },
  INTEGER: {
    displayMapper: cell => Formatter.format(cell, {decimalPlaces: 2})
  },
  DATE: {
    commonMapper: cell => moment(cell.value, "YYYY-MM-DD"),
    sortMapper: date => date.isValid() ? date.valueOf() : 0,
    displayMapper: date => date.isValid() ? date.format("L") : ""
  }
};

