import React from "react";
import createReactClass from "create-react-class";
import * as Immutable from "immutable";

import UnsavedChangesDialog from "js/common/views/unsaved-changes-dialog";
import Tabs from "js/common/views/tabs";
import ChannelsTable from "js/admin/cubetv/channels-table";
import Channel from "js/admin/cubetv/channel";
import EditRssFeeds from "js/admin/cubetv/rss-feeds/edit-rss-feeds";
import DealFlashPage from "js/admin/cubetv/deal-flash-page";
import Layout from "js/common/views/foundation-column-layout";
import {TextButton} from "js/common/views/inputs/buttons";
import Dialog from "js/common/views/dialog";
import getDefaultLeaderboardSlide from "js/admin/cubetv/leaderboard/default-leaderboard-slide";
import pure from "js/common/views/pure";
import {getUniqueName} from "js/common/utils/unique-naming";

import * as store from "js/admin/cubetv/channels-user-config";
import * as popups from "js/common/popups";
import * as timeframeRepo from "js/common/repo/backbone/timeframe-repo";
import * as CubeTv from "js/common/cubetv";
import * as auditor from "js/common/auditer";

import {CustomThemeContext} from "js/common/themes/CustomThemeProvider";

const App = createReactClass({

  getInitialState() {
    return {
      activeTabIndex: 0
    };
  },

  componentDidMount() {
    auditor.audit("gamification-admin:loaded");
  },

  render() {
    const {theme} = this.props;
    let tabs = Immutable.fromJS([
      {
        title: "Channels",
        content: <ChannelsPage theme={theme} />
      }, {
        title: "RSS Feeds",
        content: <EditRssFeeds theme={theme} />
      }, {
        title: "Deal Flashes",
        content: <DealFlashPage theme={theme} />
      }]);

    return (
        <Tabs
            selectedIndex={this.state.activeTabIndex}
            onChangeTab={tabIndex => this.setState({activeTabIndex: tabIndex})}
            tabs={tabs.toJS()}
            saveTabStateOnChange={false}
            containerStyle={{margin: 3}} />
    );
  }
});

export default (props) => {
  const {theme} = React.useContext(CustomThemeContext);
  return <App theme={theme} {...props} />;
};

const ChannelsPage = createReactClass({

  getInitialState() {
    return {
      timeframes: getTimeframes(),
      selectedChannel: null,
      channels: Immutable.List(),
      showDeleteChannelConfirmation: false
    };
  },

  componentDidMount() {
    CubeTv.loadChannels().then(channels => {
      this.setState({
        channels
      });
    });
  },

  render() {
    const {selectedChannel} = this.state;
    const {theme} = this.props;
    return (
        <div>
          <Breadcrumbs
              theme={theme}
              isChannelSelected={!!selectedChannel}
              onClickBreadcrumb={this.clearSelectedChannel} />
          {!!selectedChannel ? this.renderEditChannelPage() : this.renderChannelsPage()}
        </div>);
  },

  renderChannelsPage() {
    const {theme} = this.props;
    return (
        <div>
          <Layout allSmall={12} allMedium={5} mediumCentered={true} rowStyle={{marginTop: "1rem"}}>
            <TextButton icon="plus" label="New Channel" onClick={this.createNewChannel} />
          </Layout>
          <Layout
              allSmall={12} collapseRow={true} rowStyle={{marginTop: "1rem"}}
              columnStyle={{padding: "1rem"}}>
            <ChannelsTable
                channels={this.state.channels}
                onCloneChannelClick={this.cloneChannel}
                onDeleteChannelClick={this.onDeleteChannelClick}
                onChannelClick={this.onChannelClick} />
          </Layout>
          <ConfirmChannelDeleteDialog
              theme={theme}
              onConfirmClick={this.deleteChannel}
              onCancelClick={this.onCancelDeleteChannel}
              open={this.state.showDeleteChannelConfirmation} />
        </div>);
  },

  renderEditChannelPage() {
    const {timeframes, selectedChannel} = this.state;
    const hasUnsavedChanges = this.checkUnsavedChanges();
    const selectedChannelId = selectedChannel.get("id");
    const channelFromLocalStorage = store.getChannelFromLocalStorage(selectedChannelId);
    const lastUpdated = channelFromLocalStorage ? channelFromLocalStorage.lastModified : "N/A";
    return (
        <div style={{padding: "1rem"}}>
          <Toolbar
              onSaveChannel={this.saveChannel}
              onResetToLastSave={this.resetChannel}
              hasUnsavedChanges={hasUnsavedChanges} />
          <Channel
              hasUnsavedChanges={hasUnsavedChanges}
              channel={selectedChannel}
              onChange={this.handleChannelChange}
              channels={this.state.channels}
              timeframes={timeframes} />
          <UnsavedChangesDialog
              lastUpdated={lastUpdated}
              onRetrieveClick={this.retrieveChannel}
              onDiscardClick={this.resetChannel}
              open={!!channelFromLocalStorage} />
          <Toolbar
              onSaveChannel={this.saveChannel}
              onResetToLastSave={this.resetChannel}
              hasUnsavedChanges={hasUnsavedChanges} />
        </div>
    );
  },

  clearSelectedChannel() {
    this.setState({selectedChannel: null});
  },

  retrieveChannel() {
    const {selectedChannel} = this.state;
    const selectedChannelId = selectedChannel.get("id");
    const channelFromLocalStorage = store.getChannelFromLocalStorage(selectedChannelId);
    this.handleChannelChange(Immutable.fromJS(channelFromLocalStorage));
    store.removeChannelFromLocalStorage(selectedChannelId);
  },

  createNewChannel() {
    const channel = Immutable.fromJS(getDefaultChannel());

    CubeTv.createChannel(channel)
        .then(newChannel => {
          this.setState((state) => ({
            channels: state.channels.push(newChannel),
            selectedChannel: newChannel
          }));
        })
        .catch(e => {
          if (e.responseJSON.type) {
            popups.error(e.responseJSON.message);
          } else {
            popups.contactSupport();
          }
        });
  },

  saveChannel() {
    const {selectedChannel} = this.state;
    const channel = CubeTv.parseChannel(selectedChannel);
    saveChannel(channel)
        .then(savedChannel => {
          const selectedChannelId = selectedChannel.get("id");
          store.removeChannelFromLocalStorage(selectedChannelId);

          this.setState(({channels}) => {
            const index = channels.findIndex(c => c.get("id") === selectedChannelId);
            const newChannels = channels.set(index, savedChannel);
            return {
              channels: newChannels,
              selectedChannel: savedChannel
            };
          });

          popups.success("Your channel has been saved");
        })
        .catch(e => {
          if (e.responseJSON.type) {
            popups.error(e.responseJSON.message);
          } else {
            popups.contactSupport();
          }
        });
  },

  resetChannel() {
    const id = this.state.selectedChannel.get("id");
    const channels = this.state.channels;
    const index = channels.findIndex(c => c.get("id") === id);
    CubeTv.getChannel(id).then(originalChannel => {
      store.removeChannelFromLocalStorage(id);
      this.setState({
        channels: channels.set(index, originalChannel),
        selectedChannel: originalChannel
      });
    });
  },

  cloneChannel(id) {
    const channels = this.state.channels;
    const channelNames = channels.map(c => c.get("name"));
    const channelToClone = channels.find(c => c.get("id") === id);

    const channelCloneName = getUniqueName(channelToClone.get("name"), channelNames);
    const newChannel = channelToClone
        .set("cid", Math.random())
        .set("name", channelCloneName.substring(0, 45));
    CubeTv.createChannel(newChannel)
        .then(clonedChannel => {
          this.setState({
            channels: channels.push(clonedChannel),
            selectedChannel: clonedChannel
          });
        })
        .catch(e => {
          if (e.responseJSON.type) {
            popups.error(e.responseJSON.message);
          } else {
            popups.contactSupport();
          }
        });
  },

  onChannelClick(id) {
    const selectedChannel = this.state.channels.find(c => c.get("id") === id);
    this.setState({
      selectedChannel
    });
  },

  onDeleteChannelClick(id) {
    this.channelIdToDelete = id;
    this.setState({
      showDeleteChannelConfirmation: true
    });
  },

  onCancelDeleteChannel() {
    this.setState({
      showDeleteChannelConfirmation: false
    });
  },

  deleteChannel() {
    CubeTv
        .deleteChannel(this.channelIdToDelete)
        .then(() => {
          const {channels} = this.state;
          const index = channels.findIndex(c => c.get("id") === this.channelIdToDelete);
          this.setState({
            channels: channels.delete(index),
            showDeleteChannelConfirmation: false
          });
        })
        .catch(() => {
          popups.contactSupport();
        });
  },

  checkUnsavedChanges() {
    const {channels, selectedChannel} = this.state;
    const savedChannel = channels.find(channel => channel.get("id") === selectedChannel.get("id"));
    return !savedChannel.get("json").equals(selectedChannel.get("json")) ||
        !(savedChannel.get("name") === (selectedChannel.get("name"))) ||
        !(savedChannel.get("description") === (selectedChannel.get("description")));
  },

  handleChannelChange(changedChannel) {
    this.setState({
      selectedChannel: changedChannel
    });
  }

});

const saveChannel = channel => {
  if (channel.has("id")) {
    return CubeTv.updateChannel(channel);
  } else {
    return CubeTv.createChannel(channel);
  }
};

const Toolbar = pure(({
  onSaveChannel,
  onResetToLastSave,
  hasUnsavedChanges
}) => (
    <div className="row collapse" style={{marginTop: "1em", marginBottom: "1em"}}>
      <div className="small-12 text-center columns hide-for-small">
        <TextButton
            disabled={!hasUnsavedChanges}
            icon="history"
            label="Cancel"
            onClick={onResetToLastSave}
            style={{marginRight: "0.5rem"}} />
        <TextButton
            disabled={!hasUnsavedChanges}
            icon="floppy-o"
            label="Save"
            onClick={onSaveChannel}
            style={{marginLeft: "0.5rem"}} />
      </div>
    </div>
));

const Breadcrumbs = pure(({
  theme,
  isChannelSelected,
  onClickBreadcrumb
}) => (
    <div
        style={{
          width: "100%",
          borderTop: `1px solid ${theme.palette.background.card}`,
          borderBottom: `1px solid ${theme.palette.background.card}`,
          background: theme.palette.background.card,
          padding: "10px",
          fontSize: "0.7rem",
          textTransform: "uppercase"
        }}>
        <span onClick={onClickBreadcrumb} style={{cursor: "pointer"}}>
            <span style={{color: isChannelSelected ? theme.palette.textColor : theme.palette.primary.main}}>
                <i className="fa fa-list-alt" aria-hidden="true" style={{paddingRight: "10px"}} />
                Channels List
            </span>
        </span>
      {isChannelSelected &&
          <span>
                <i
                    className="fa fa-chevron-right"
                    style={{fontSize: "10px", paddingLeft: "10px", paddingRight: "10px"}}
                    aria-hidden="true" />
                <span style={{color: theme.palette.primary.main}}>Edit Channel</span>
            </span>}
    </div>
));

const ConfirmChannelDeleteDialog = pure(({
  onConfirmClick,
  onCancelClick,
  open,
  theme
}) => (
    <Dialog
        bodyStyle={{overflow: "visible", color: theme.palette.textColor}}
        actionsContainerStyle={{paddingRight: "2rem"}}
        title="Delete Channel?"
        actions={[
          <TextButton icon="history" label="Cancel" onClick={onCancelClick} />,
          <TextButton
              key="confirm-delete"
              type="primary"
              label="Delete"
              icon="trash"
              style={{marginRight: 8, marginLeft: "0.5rem", marginRight: "0.5rem"}}
              onClick={onConfirmClick} />
        ]}
        autoDetectWindowHeight={true}
        open={open}>
        <span><i
            className="fa fa-warning-triangle"
            style={{paddingRight: 5}} />You can not undo deleting a channel.</span>
    </Dialog>
));

const getTimeframes = () => Immutable.fromJS(timeframeRepo.getAll().toJSON());

const getDefaultChannel = () => {
  const defaultLeaderboardSlide = getDefaultLeaderboardSlide();
  return {
    cid: Math.random(),
    name: "New Channel",
    description: "",
    createdByUserId: null,
    dateCreated: "",
    lastModified: "",
    json: {
      pages: [defaultLeaderboardSlide],
      dealflash: {
        id: "fullscreen-dealflash",
        timing: 30000,
        layout: {
          id: "Fullscreen",
          fullscreen: [
            {
              visualizationId: "DealFlash"
            }]
        },
        showNewsticker: false
      },
      newsfeeds: {
        newsfeeds: ["bbc-news", "bbc-sports", "financial-times"]
      }
    }
  };
};
