/** @jsxImportSource @emotion/react */
import createReactClass from "create-react-class";
import ReactPropTypes from "prop-types";
import PureRenderMixin from "react-addons-pure-render-mixin";

import {Column, Layout, Row} from "js/common/views/foundation-column-layout";
import {TextButton} from "js/common/views/inputs/buttons";
import DropdownToggle from "js/common/views/react-dropdown-toggle";
import DatePicker from "js/common/views/inputs/timeframe-picker/react-datepicker";
import ErrorMsg from "js/common/views/error";
import * as TimeframeRepo from "js/common/repo/backbone/timeframe-repo";
import React from "react";
import {CustomThemeContext} from "js/common/themes/CustomThemeProvider";

const TimeframePicker = createReactClass({
  displayName: "TimeframePicker",

  mixins: [PureRenderMixin],

  propTypes: {
    onChange: ReactPropTypes.func.isRequired,
    timeframe: ReactPropTypes.object.isRequired,
    isDisabled: ReactPropTypes.bool,
    timeframes: ReactPropTypes.array,
    onClose: ReactPropTypes.func,
    label: ReactPropTypes.string,
    labelTooltip: ReactPropTypes.string
  },

  getDefaultProps() {
    return {
      isDisabled: false,
      onClose: () => {}
    };
  },

  getInitialState() {
    const {timeframe} = this.props;
    return {
      isOpen: false,
      customStartDate: timeframe.get("start"),
      customEndDate: timeframe.get("end"),
      customDateRangeError: null
    };
  },

  UNSAFE_componentWillReceiveProps(nextProps) {
    const currentTimeframe = this.props.timeframe;
    const nextTimeframe = nextProps.timeframe;
    if (nextTimeframe.getStart() !== currentTimeframe.getStart()
        || nextTimeframe.getEnd() !== currentTimeframe.getEnd()) {
      this.setState({
        customStartDate: nextTimeframe.get("start"),
        customEndDate: nextTimeframe.get("end")
      });
    }
  },

  render() {
    return (
        <DropdownToggle
            label={this.props.label}
            labelTooltip={this.props.labelTooltip}
            primaryText={this.props.timeframe.get("name")}
            secondaryText={this.getTimeframeDateRangeLabel()}
            isDisabled={this.props.isDisabled}
            isOpen={this.state.isOpen}
            onToggleButtonClick={this.toggleDropdown}
            onRequestClose={this.closeDropdown}
            testId={"timeframe-picker"}>
          {this.renderTimeframes()}
        </DropdownToggle>);
  },

  getTimeframeDateRangeLabel() {
    const {timeframe} = this.props;
    if (timeframe.get("id").toUpperCase() === "CUSTOM") {
      return;
    }

    const startDate = timeframe.get("start").format("ll").replace(",", "");
    const endDate = timeframe.get("end").format("ll").replace(",", "");
    return `(${startDate} to ${endDate})`;
  },

  toggleDropdown() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  },

  closeDropdown() {
    const {customStartDate, customEndDate} = this.state;
    const {timeframe, onClose} = this.props;
    this.setState({
      isOpen: false,
      customStartDate: customStartDate.isValid() ? customStartDate : timeframe.get("start"),
      customEndDate: customEndDate.isValid() ? customEndDate : timeframe.get("end")
    });
    onClose();
  },

  renderTimeframes() {
    const timeframes = this.props.timeframes || getDefaultTimeframes();
    const {customStartDate, customEndDate} = this.state;
    return (
        <div>
          <Layout allSmall={12} rowStyle={{marginTop: "0", padding: "0 1rem"}}>
            {timeframes.map(timeframe => {
              const isDeleted = timeframe.get("isDeleted");
              const isHidden = !timeframe.get("visible");
              const warningLabelStyle = {
                color: "#000",
                borderRadius: 3,
                padding: "0 5px",
                marginLeft: 8
              };
              return <PresetTimeframe
                  key={timeframe.get("id")}
                  id={timeframe.get("id")}
                  theme={this.props.theme}
                  label={<span>
                              <span style={{color: "inherit"}}>{timeframe.get("name")}</span>
                    {isDeleted && <strong style={{...warningLabelStyle, backgroundColor: "#f00"}}>
                      DELETED
                    </strong>}
                    {isHidden && <strong style={{...warningLabelStyle, backgroundColor: "#ff8d00"}}>
                      HIDDEN
                    </strong>}
                            </span>}
                  onClick={() => this.handlePresetTimeframeClick(timeframe)} />;
            })}
          </Layout>

          <Row style={{padding: "0 1rem"}}>
            <Column small={12}>
              <hr style={{
                margin: "0.5rem 0",
                border: `1px solid #eee`,
                borderColor: `${this.props.theme.palette.background.paper}`
              }} />
              <div className="small-12 columns" style={customDateHeadingStyle(this.props.theme)}>
                Choose a custom date range
              </div>
            </Column>
          </Row>
          <Layout allSmall={12} allLarge={6} rowStyle={{margin: "0 0.5rem"}} columnStyle={{padding: "0 0.5rem"}}>
            <CustomDatePicker
                label="Start Date"
                value={customStartDate}
                onChange={this.handleStartDateChange}
                calendarPosition="left" />
            <CustomDatePicker
                label="End Date"
                minDate={customStartDate}
                value={customEndDate}
                onChange={this.handleEndDateChange}
                calendarPosition="right" />
          </Layout>
          {customEndDate.isBefore(customStartDate) &&
              <ErrorMsg text="End Date cannot be before Start Date" style={{margin: "0.5rem 1rem"}} />}
          <div
              style={{
                marginTop: "1rem",
                marginBottom: "1rem",
                padding: "0 1rem",
                textAlign: `${this.props.theme.themeId === "light" ? "right" : "center"}`
              }}
          >
            <TextButton
                type="primary"
                suffixIcon="clock-o"
                label="Update"
                disabled={
                    customEndDate.isBefore(customStartDate)
                    || !customStartDate.isValid()
                    || !customEndDate.isValid()
                }
                onClick={this.handleCustomTimeframeSelection}
                testId={"timeframe-picker-update"}/>
          </div>
        </div>);
  },

  handlePresetTimeframeClick(timeframe) {
    this.props.onChange(timeframe);
    this.closeDropdown();
  },

  handleStartDateChange(date) {
    this.setState({
      customStartDate: date
    });
  },

  handleEndDateChange(date) {
    this.setState({
      customEndDate: date
    });
  },

  handleCustomTimeframeSelection() {
    const {customStartDate, customEndDate} = this.state;
    const timeframe = TimeframeRepo.parseCustom({
      start: customStartDate,
      end: customEndDate
    });
    this.props.onChange(timeframe);
    this.closeDropdown();
  }

});

const CustomDatePicker = React.memo(({
  label,
  minDate,
  value,
  style,
  onChange,
  calendarPosition
}) => {
  const {palette, themeId} = React.useContext(CustomThemeContext).theme;
  return (
      <div style={style} className={`picker-${themeId}`}>
        <label style={{
          cursor: "default",
          marginTop: "13px",
          marginBottom: "5px",
          color: palette.text.main,
          textTransform: themeId === "light" ? "uppercase" : "none",
          fontSize: themeId === "light" ? 13 : 12
        }}>
          {label}
        </label>
        <DatePicker onDateChange={onChange} minDate={minDate} value={value} calendarPosition={calendarPosition} />
      </div>
  );
});

const PresetTimeframe = React.memo(({
  id,
  label,
  onClick,
  theme
}) => <div
    data-test-id={"preset-timeframe-" + id}
    css={presetTimeframeCss(theme)}
    onClick={onClick}>{label}</div>);

const getDefaultTimeframes = () => TimeframeRepo
    .getAll()
    .filter(t => t.get("visible") && !t.get("isDeleted"));

const padding = {
  paddingTop: "7px",
  paddingRight: "5px",
  paddingBottom: "7px",
  paddingLeft: "10px"
};

const presetTimeframeCss = theme => ({
  padding: theme.themeId === "light" ? "7px 5px" : "5px",
  boxSizing: "border-box",
  cursor: "pointer",
  fontSize: "13px",
  "&:hover": {
    background: theme.palette.primary.main,
    color: theme.palette.text.inverted
  }
});

const customDateHeadingStyle = theme => ({
  fontSize: theme.themeId === "light" ? "0.7rem" : "0.6rem",
  textTransform: "uppercase",
  ...padding
});

export default (props) => {
  const {theme} = React.useContext(CustomThemeContext);
  return <TimeframePicker theme={theme} {...props} />;
};
